@include fade-from-above(fade-up-popup, -50px, 0);

@include fade-from-above(fade-down-popup, -50px, 0);

$modal-timing: .35s;

.modal-backdrop {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: rgb(0 0 0 / .8);

  .modal {
    position: relative;
    overflow: auto;
    width: 90vw;
    background-color: $white;
    border-radius: $border-radius-20;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @include small-desktop {
      width: 50rem;
    }

    &.modal-bounce-in {
      animation: fade-up-popup .5s ease-in-out forwards;
    }

    &.modal-bounce-out {
      animation: fade-down-popup $modal-timing ease-in-out forwards reverse;
    }
  }

  &.modal-hidden {
    opacity: 0;
    pointer-events: none;
    transition: opacity $modal-timing ease-in-out $modal-timing, visibility ease-in-out .5s;
    visibility: hidden;
  }
}

.close {
  position: absolute;
  top: 2rem;
  right: 2rem;
  display: flex;
  width: 2rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  background-color: transparent;
  border-radius: 2rem;
  cursor: pointer;
  transition: all .5s ease-in-out;

  .line {
    position: inherit;
    width: 1.5rem;
    height: .2rem;
    background-color: $secondary-red;

    &:nth-child(1) {
      transform: rotate(45deg);
    }

    &:nth-child(2) {
      transform: rotate(-45deg);
    }
  }

  &:hover {
    background-color: rgb(128 128 128 / .1);
  }
}
