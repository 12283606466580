$action-icons-border-radius: .3rem;
$tooltip-position: -4rem;

@include fade-from-above(fade-from-above, 2rem, $tooltip-position);

$possible-button-sizes: (
  xs: 2,
  sm: 3,
  md: 4,
  lg: 5,
  xl: 6,
);

.icon-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $border-radius-5;
  transition: filter .5s ease-in-out;

  span {
    color: currentcolor;
  }

  @each $key, $size in $possible-button-sizes {
    &.size-#{$key} {
      width: #{$size}rem;
      height: #{$size}rem;

      > span {
        font-size: $font-size-md;
        transform: scale(calc($size * .4));
      }
    }
  }

  &.rounded {
    border-radius: 50%;
  }

  &:hover {
    filter: brightness(.85);
  }

  &.primary {
    background-color: $primary-mint;

    &:hover,
    &:active,
    &:focus {
      background-color: $active-primary-mint;
    }
  }

  &.secondary {
    background-color: $primary-blue;

    &:hover,
    &:active,
    &:focus {
      background-color: $active-primary-blue;
    }
  }

  &.transparent {
    background-color: transparent;
  }

  &:disabled {
    opacity: .3;
  }

  .tooltip {
    position: absolute;
    z-index: 999;
    bottom: .5rem; /* Adjust the bottom position */
    left: -4.7rem;
    width: 20rem;
    padding: .5rem 1rem;
    animation: fade-from-above .6s ease-in-out forwards;
    background-color: $dark;
    border-radius: $border-radius-5;
    color: $white;
    font-family: $avenir-font;
    font-size: $font-size-sm;

    &::before {
      position: absolute;
      bottom: -.5rem;
      left: 5.5rem;
      width: 1rem;
      height: 1rem;
      background-color: $dark;
      border-radius: .3rem;
      content: "";
      transform: rotate(45deg);
    }
  }
}
