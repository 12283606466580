svg.logo {
  width: 17rem;
  height: 5rem;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  cursor: pointer;
  transition: all 2s ease-in-out;

  &:hover {
    .logo-rotate {
      transform: rotate(360deg);
    }
  }

  path {
    &.logo-rotate {
      opacity: 0;

      @for $i from 11 through 15 {
        &:nth-child(#{$i}) {
          animation-fill-mode: backwards;
          opacity: 1;
          transform: rotate(calc(#{$i - 10} + 25) deg);
          transform-box: fill-box;
          transform-origin: center;
          transition: transform .4s ease-in-out calc(.8s - .6s / (#{$i - 10} / #{$i - 10}) + #{$i - 10} * .2s),
            opacity .4s ease-in-out;
        }
      }
    }

    &.logo-letter {
      opacity: 1;

      @for $i from -1 through 11 {
        &:nth-child(#{$i}) {
          opacity: 1;
          transition: opacity .4s ease-in-out calc(.2s * #{$i});
        }
      }

      @include tablet {

        @for $i from 11 through -1 {
          &:nth-child(#{$i}) {
            animation-delay: calc(.4s - .6s / (#{$i} * #{$i}) + #{$i} * .2s);
            animation-fill-mode: forwards;
            opacity: 1;
            transition: opacity .4s ease-in-out calc(.8s - .6s / (#{$i} * #{$i}) + #{$i} * .2s);
          }
        }
      }
    }
  }
}
