.slick-slider {
  .slick-list {
    position: relative;
    width: calc(100% - 6rem);
    margin: 0 auto;

    &::before,
    &::after {
      position: absolute;
      z-index: 999;
      top: 0;
      width: 1.5rem;
      height: 100%;
      background: rgb(255 255 255);
      content: "";
    }

    &::before {
      left: 0;
      background: linear-gradient(90deg, $white-600 0%, rgba($white-600, 0) 100%);
    }

    &::after {
      right: 0;
      background: linear-gradient(90deg, rgba($white-600, 0) 0%, $white-600 100%);
    }

    .slick-track {
      position: relative;
      display: flex;
      width: calc(100% - 2rem);
      margin-left: 0;
      gap: 2rem;
    }
  }

  .arrow-left,
  .arrow-right {
    position: absolute;
    top: 0;
    display: block;
    height: 100%;
    background-color: unset;
    color: unset;
    line-height: 0;

    @include desktop {
      top: 30%;
      width: 3rem;
      height: 3rem;
    }
  }

  .arrow-left {
    left: 0;
  }

  .arrow-right {
    right: 0;
  }
}
