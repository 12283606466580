$tier-card-desktop: 30.6rem;

.container-flip {
  position: relative;
  height: $tier-card-desktop;

  > .card-wrapper {
    position: absolute;
    width: 24.7rem;
    height: $tier-card-desktop;
    transform-style: preserve-3d;
    transition: transform 1s;

    .inner-wrapper {
      max-width: 23.5rem;
      align-self: center;
    }

    &.flipped {
      transform: rotateY(180deg);
    }

    > div.card-face {
      position: absolute;
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      margin: 0;
      backface-visibility: hidden;

      &.card-face-front {
        overflow: visible !important;
        height: 100%;
        justify-content: center;
        transform: rotateY(0deg);
      }

      &.card-face-back {
        height: 100%;
        align-items: start;
        justify-content: center;
        background-color: $primary-blue;
        gap: 3;
        text-align: left;
        transform: rotateY(180deg);

        ul {
          position: relative;
          overflow: hidden;
          max-height: 14rem;
          mask-image: linear-gradient(to bottom, rgb(0 0 0 / 1) 60%, rgb(0 0 0 / 0) 100%);

          li {
            margin-left: 1.5rem;
            list-style-type: disc;
          }
        }

        button {
          border: .1rem solid $white;
          background-color: transparent;
          color: $white;

          &:hover,
          &:focus {
            background-color: $white;
            box-shadow: 0 0 .4rem $white;
            color: $primary-blue;
          }
        }

        .tier-tokens-pulled {
          background-color: rgba($active-primary-blue, .14);
          border-radius: 2.2rem;
        }
      }
    }
  }
}
