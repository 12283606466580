main.user-page {
  height: 100%;
  background-color: $white-600;

  .tabs {
    background-color: transparent;

    .tab-list {
      max-width: 40rem;
      margin: 0 auto;
    }
  }

  .user-header {
    > .full-area {
      position: relative;
      opacity: 1;

      .parallax-container {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 20rem;

        @include small-desktop {
          height: 16rem;
        }

        .parallax-banner {
          height: 30rem;
          background-position: bottom;
          background-repeat: no-repeat;
          background-size: cover;

          @include small-desktop {
            height: 40rem;
          }
        }
      }

      .user-container {
        display: flex;
        flex-direction: column;
        padding: 0 4.5rem;
        margin: 0 auto;
        pointer-events: none;
        transform: translateY(-4rem);

        @include small-desktop {
          flex-direction: row;
          transform: translateY(-6rem);
        }

        .user-name {
          padding-top: 3.6rem;
          font-size: .8rem;

          @include small-desktop {
            font-size: 1.5rem;
          }
        }

        .user-avatar {
          width: 9rem;
          height: 9rem;
          border-color: $gray-500;

          @include small-desktop {
            width: 12rem;
            height: 12rem;
          }
        }
      }
    }
  }

  .user-tokens-grid {
    position: relative;
    display: grid;
    align-self: center;
    gap: 3rem 1rem;
    grid-template-columns: repeat(1, 1fr);

    @include tablet {
      grid-template-columns: repeat(2, 1fr);
    }

    @include desktop {
      grid-template-columns: repeat(3, 1fr);
    }

    @include large-desktop {
      grid-template-columns: repeat(4, 1fr);
    }

    @include resolution(1600px) {
      grid-template-columns: repeat(5, 1fr);
    }
  }

  .token-columns {
    --bs-columns: 3;
  }

  .page-margin {
    margin: 0 2rem;

    @include tablet {
      margin: 0 10rem;
    }

    @include small-desktop {
      margin: 0 23rem;
    }
  }

  .user-panel {
    background-color: $white;
    border-radius: $border-radius-10;
    box-shadow: 0 1rem 1.5rem 0 rgb(205 205 205 / .35);

    &:-webkit-scrollbar-track {
      box-shadow: inset 0 0 .6rem $white-600;
    }

    &:-webkit-scrollbar-thumb {
      background-color: $white-600;
      outline: .1rem solid slategrey;
    }

    &.panel-background {
      background-color: transparent;
      box-shadow: unset;

      @include small-desktop {
        background-color: $white;
        box-shadow: 0 1rem 1.5rem 0 rgb(205 205 205 / .35);
      }
    }

    &.panel-tokens {

      @include small-desktop {
        background-color: transparent;
        box-shadow: unset;
      }
    }
  }
}
