@import "flatpickr/dist/themes/airbnb.css";

$input-border: .2rem;

.date-picker-input {
  position: relative;
  display: flex;
  width: 100%;
  height: 4.4rem;
  flex-direction: column;
  padding: 0 .8rem;
  background-color: $white;
  border-radius: $border-radius-5;
  box-shadow: inset 0 0 0 $input-border $white-600;
  font-family: $nunito-font;
  font-family: $avenir-font;
  font-size: $font-size-md;
  font-weight: 400;
  transition: height .2s ease-in-out;

  .flatpickr-input {
    position: absolute;
    z-index: 50;
    width: calc(100% - 3rem);
    height: 100%;
  }

  .icon-button {
    position: absolute;
    right: 0;
  }

  &::placeholder {
    color: $gray-300;
  }

  &:hover {
    box-shadow: inset 0 0 0 $input-border $gray-400;
    transition: box-shadow .2s ease-in-out;
  }

  &:active {
    box-shadow: inset 0 0 0 $input-border $primary-blue;
    transition: box-shadow .2s ease-in-out;
  }

  &:focus {
    &::placeholder {
      color: transparent;
    }
  }

  &.input-side-icon {
    padding: 0 1.6rem 0 .8rem;
  }
}

.flatpickr-calendar,
.flatpickr-time {
  font-family: $nunito-font;
}
