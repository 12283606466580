@include fade-from-above(fade-up-tooltip, 10px, -20px);

div.date-time-wrapper {
  width: max-content;
}

div.date-time-tooltip {
  position: fixed;
  top: 0;

  > .container {
    position: absolute;
    bottom: 0;
    left: -7rem;
    width: 15rem;
    flex-wrap: wrap;
    padding: .5rem 1rem;
    animation: fade-up-tooltip .6s ease-in-out forwards;
    background-color: $dark;
    border-radius: $border-radius-5;
    color: $white;
    font-family: $avenir-font;
    font-size: $font-size-sm;
    text-align: center;

    &::before {
      position: absolute;
      bottom: -.5rem;
      left: 50%;
      width: 1rem;
      height: 1rem;
      margin-left: -1rem;
      background-color: $dark;
      border-radius: .3rem;
      content: "";
      transform: rotate(45deg);
    }
  }
}
