@include fade-from-side(error-from-side, -2rem, 0);

$input-border: .2rem;

.input-group {
  position: relative;
  display: flex;
  flex-direction: column;
  transition: height .2s ease-in-out;

  > .input-text {
    margin-bottom: .8rem;

    > label {
      margin-bottom: 1rem;
      color: $primary-blue;
      font-family: $avenir-font;
      font-size: $font-size-md;
      font-weight: 500;
    }

    > p {
      display: flex;
      overflow: hidden;
      margin-top: .8rem;
      -webkit-box-orient: vertical;
      color: $gray-300;
      font-size: $font-size-sm;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      text-overflow: ellipsis;
    }
  }

  > .input {
    position: relative;
    height: 4rem;
    color: $dark;
    transition: box-shadow margin-bottom height .2s ease-in-out;

    > input {
      width: 100%;
      height: 100%;
      padding: 0 .8rem;
      background-color: $white;
      border-radius: $border-radius-5;
      box-shadow: inset 0 0 0 $input-border $white-600;
      font-family: $avenir-font;
      font-size: $font-size-md;
      font-weight: 400;

      &::placeholder {
        color: $gray-300;
      }

      &:hover {
        box-shadow: inset 0 0 0 $input-border $gray-400;
        transition: box-shadow .2s ease-in-out;
      }

      &:active {
        box-shadow: inset 0 0 0 $input-border $primary-blue;
        transition: box-shadow .2s ease-in-out;
      }

      &:focus {
        &::placeholder {
          color: transparent;
        }
      }

      &.input-side-icon {
        padding: 0 1.6rem 0 .8rem;
      }
    }

    &.error {
      margin-bottom: 2.5rem;

      input {
        padding: 0 4rem 0 .8rem;
        box-shadow: inset 0 0 0 .1rem $secondary-red;

        &:hover {
          box-shadow: inset 0 0 0 $input-border $gray-400;
        }

        &:active {
          box-shadow: inset 0 0 0 $input-border $secondary-red;
        }

        &:focus {
          &::placeholder {
            color: transparent;
          }
        }
      }
    }

    label.error {
      position: relative;
      top: 1rem;
      left: 0;
      display: flex;
      width: 100%;
      padding: 0 1rem;
      animation: error-from-side .5s ease-in-out forwards;
      color: $secondary-red;
      font-size: $font-size-sm;

      &.label-overflow-container {
        top: 0;
        display: flex;
        width: max-content;
      }
    }
  }

  .input-icon {
    position: absolute;
    top: .5rem;
    right: -.25rem;
    display: flex;
    width: 3rem;
    height: 3rem;
    box-sizing: border-box;
    align-items: center;

    &.error-icon {
      color: $secondary-red;
    }

    &.password-icon {
      color: $gray-400;
      cursor: pointer;
    }
  }

  .right-side-icon {
    color: $primary-blue;
    font-size: 2.1rem;
  }
}
