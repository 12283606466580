.collapse-panel {
  position: relative;
  overflow: hidden;
  overflow-y: auto;
  transition-duration: .35s;
  transition-property: max-height, opacity;
  transition-timing-function: ease-in-out;

  &.collapsed {
    max-height: 0;
    opacity: 0;
  }
}

.collapse-panel-header {
  padding: 2rem 1.5rem;

  @include small-desktop {
    padding: 1rem;
  }
}
