details {
  cursor: pointer;

  > summary {
    &::-webkit-details-marker {
      display: none;
    }

    .chevron-arrow {
      pointer-events: none;
      transition: transform .35s ease-in-out;
    }
  }

  &[open] {
    > summary .chevron-arrow {
      transform: rotate(180deg);
    }
  }

  &[closed] {
    > summary .chevron-arrow {
      transform: rotate(0deg);
    }
  }

  .content {
    margin-top: 2rem;
  }
}
