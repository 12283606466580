$radio-size: 2.1rem;
$inner-radio-size: calc($radio-size - .4rem);

.radio {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  input {
    position: relative;
    width: $radio-size;
    height: $radio-size;
    border: .1rem solid $primary-blue;
    background-color: $white;
    border-radius: $radio-size;
    cursor: inherit;

    &::before {
      position: absolute;
      top: calc($inner-radio-size / 2);
      left: calc($inner-radio-size / 2);
      width: 0;
      height: 0;
      box-sizing: border-box;
      border: .1rem solid $white;
      background-color: $primary-blue;
      border-radius: calc($inner-radio-size / 2);
      content: "";
      transition-duration: .3s;
      transition-property: width, height, top, left;
      transition-timing-function: ease-in-out;
    }

    &:checked {
      &::before {
        top: .1rem;
        left: .1rem;
        width: $inner-radio-size;
        height: $inner-radio-size;
      }
    }
  }

  > label {
    display: flex;
    align-items: flex-end;
    cursor: inherit;
    font-family: $avenir-font;
    font-size: $font-size-md;
    font-weight: 500;
    gap: 1rem;

    &::selection {
      background-color: transparent;
    }
  }

  &.disabled {
    cursor: not-allowed;

    input {
      border: .1rem solid $gray-400;
      background-color: $white;

      &::before {
        border: .1rem solid $white;
        background-color: $gray-400;
      }
    }

    > label {
      color: $gray-400;
    }
  }

  &.checked {
    > label {
      font-weight: 600;
    }
  }
}
