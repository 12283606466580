@include fade-from-above(fade-up-warn, -50px, 0);

main.edit {
  display: grid;
  height: calc(100vh - $navbar-height);
  background-color: $white-600;
  grid-template-columns: 1fr;

  @include small-desktop {
    grid-template-columns: 28rem 1fr;
    overflow-y: hidden;
  }

  .side-container {
    overflow-y: unset;

    @include small-desktop {
      overflow-y: auto;
    }

    .settings-panel {
      width: 100%;

      @include desktop {
        max-width: 99.2rem;
      }

      > .panel {
        padding: 3.2rem;

        input[type="number"].zip-code {
          appearance: textfield;

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            margin: 0;
            appearance: none;
          }
        }

        .right-buttons {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: right;
          gap: 2rem;

          > .save {
            order: 2;
          }

          > .cancel {
            order: 1;
          }
        }

        .buttons-container {
          display: grid;
          align-items: center;
          justify-content: center;
          gap: 2rem;

          > .save-settings {
            order: 1;
          }

          > .delete-account {
            order: 2;
          }

          @include small-desktop {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            > .save-settings {
              order: 2;
            }

            > .delete-account {
              order: 1;
            }
          }
        }
      }
    }
  }

  .warning {
    animation: fade-up-warn .5s linear forwards;
    background-color: rgba($secondary-red, .1);

    > p {
      max-width: 60rem;
    }
  }

  #settings-success,
  #delete-account {
    p {
      max-width: 35.6rem;
    }
  }

  .shipping-details-preview {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 1rem;

    > .details {
      order: 1;
    }

    > .buttons-container {
      order: 2;
    }
  }

  .shipping-details-form {
    border-radius: 1rem;
  }

  .shipping-modal-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;

    .edit {
      border: .1rem solid $secondary-blue;
      background: white;
    }
  }
}
