main.feed {
  display: grid;
  height: calc(100vh - $navbar-height);
  background-color: $white-600;
  grid-template-columns: 1fr;

  @include small-desktop {
    grid-template-columns: 28rem 1fr;
    overflow-y: hidden;
  }

  .side-container {
    overflow-y: unset;

    @include small-desktop {
      overflow-y: auto;
    }

    .side-container-wrapper {
      margin-bottom: 2rem;

      > h2 {
        margin-top: 3rem;
        margin-bottom: 1rem;

        @include small-desktop {
          margin: 5.5rem auto;
        }
      }
    }

    .mysubs-wrapper {
      position: unset;
      top: unset;

      @include small-desktop {
        position: sticky;
        top: 2rem;
      }
    }
  }
}

.feed-content {
  hr {
    width: unset;
    max-width: 100%;
    border-top: .1rem solid $gray-500;
    margin-right: .75rem;
    margin-left: .75rem;
  }

  > .feed-tile {
    position: unset;
    top: unset;

    @include large-desktop {
      position: sticky;
      top: 40rem;
    }

    &:nth-child(1) {

      @include small-desktop {
        order: 3;
      }
    }
  }
}

.panel {
  &.panel-feed {
    background-color: transparent;
    box-shadow: unset;

    @include small-desktop {
      background-color: $white;
      box-shadow: 0 1rem 1.5rem 0 rgb(205 205 205 / .35);
    }
  }

  &.panel-filter {
    position: sticky;
    z-index: 100;
    top: 0;
    display: grid;
    padding: 2rem 1.5rem 1.5rem;
    grid-column: auto / span 12;
    grid-template-columns: repeat(12, 1fr);

    @include small-desktop {
      position: unset;
      top: unset;
      background-color: transparent;
      box-shadow: unset;

      > div:nth-child(2) {
        display: flex;
        min-width: 21.5rem;
        flex-direction: column;
        justify-self: flex-end;
      }
    }
  }

  &.panel-posts {
    padding: 1.5rem 2rem;

    @include small-desktop {
      background-color: transparent;
      box-shadow: unset;
    }

    h4 {
      margin-bottom: 2.5rem;
    }
  }
}

.tier-card {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-bottom: .1rem solid $gray-500;
  margin: 0 1rem;
  gap: 3rem;

  @include small-desktop {
    justify-content: flex-start;
  }

  .tier-card-image {
    max-width: 7rem;
    max-height: 7rem;
    flex-shrink: 0;

    > img {
      width: 100%;
    }
  }

  .tier-card-info {
    width: 20rem;

    > p {
      overflow: hidden;
      max-width: 15rem;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    > h6 {
      overflow: hidden;
      max-width: 15rem;
      text-overflow: ellipsis;
    }

    @include small-desktop {
      max-width: 6rem;
    }

    @include desktop {
      max-width: 15rem;
    }
  }
}
