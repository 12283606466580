.loader-wrapper {
  position: relative;
  display: flex;
  width: 6rem;
  height: 6rem;
  align-items: center;
  justify-content: center;

  .preloader {
    position: relative;
    width: 4.5rem;
    height: 4.5rem;

    span {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      @for $i from 1 through 9 {
        &:nth-child(#{$i}) {
          transform: rotate(calc(45deg * #{$i}));

          &::before {
            position: absolute;
            top: 0;
            left: 0;
            width: 1.2rem;
            height: 1.2rem;
            animation: animate 2s linear infinite;
            animation-delay: calc(.25s * #{$i});
            background: $dark;
            border-radius: 50%;
            content: "";
            transform: scale(calc(.11 * #{$i}));
          }
        }
      }
    }
  }

  &::after {
    position: absolute;
    width: 2.3rem;
    height: 2.3rem;
    background-color: $primary-mint;
    border-radius: 100%;
    content: "";
  }
}

@keyframes animate {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}
