.button-back {
  width: min-content;
  margin-bottom: 2rem;
  margin-left: -1.5rem;
  cursor: pointer;

  > button > span {
    color: $primary-blue !important;
    transform: scale(3.5);

    @include small-desktop {
      transform: scale(1.6);
    }
  }

  .back-icon {
    > span {
      transform: scale(3) !important;

      @include small-desktop {
        transform: scale(1.6) !important;
      }
    }
  }

  p {
    padding-top: .3rem;
    color: $primary-blue;
    text-decoration: none;
  }
}
