.preloader {
  position: relative;
  width: 4rem;
  height: 4rem;

  span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @for $i from 1 through 9 {
      &:nth-child(#{$i}) {
        transform: rotate(calc(45deg * #{$i}));

        &::before {
          position: absolute;
          top: 0;
          left: 0;
          width: 1.2rem;
          height: 1.2rem;
          animation: animate 2s linear infinite;
          animation-delay: calc(.25s * #{$i});
          background: $primary-blue;
          border-radius: 50%;
          content: "";
          transform: scale(calc(.11 * #{$i}));
        }
      }
    }
  }
}

@keyframes animate {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}
