.avatar {
  width: 8rem;
  height: 8rem;
  background-color: $gray-400;
  border-radius: 50%;

  > img {
    object-fit: cover !important;
  }

  @for $i from 1 through 18 {
    $size: #{$i}rem;

    @include media-breakpoint-up(xs) {
      &.avatar-#{$i} {
        width: $size;
        height: $size;
      }
    }

    @each $breakpoint-key, $breakpoint-values in $grid-breakpoints {

      @include media-breakpoint-only($breakpoint-key) {
        &.avatar-#{$breakpoint-key}-#{$i} {
          width: $size;
          height: $size;
        }
      }
    }
  }

  &.avatar-border {
    border: .5rem solid $gray-500;
    transition: border .1s ease-in-out;

    @include small-desktop {
      border: 1rem solid $gray-500;
    }

    &:hover {
      border-width: .7rem;
    }
  }

  &.loading {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
  }
}
