main.offers {
  display: grid;
  height: calc(100vh - $navbar-height);
  background-color: $white-600;
  grid-template-columns: 1fr;

  .container-fluid {

    @include small-desktop {
      overflow-y: auto;
    }
  }

  .offers-grid {
    > div:first-child {
      order: 2;
    }

    > div:last-child {
      order: 1;
    }

    @include small-desktop {
      > div:first-child {
        order: 1;
      }

      > div:last-child {
        order: 2;
      }
    }
  }

  @include small-desktop {
    grid-template-columns: 28rem 1fr;
    overflow-y: hidden;
  }

  .token-price-image {
    padding: .25rem;
    border: .1rem solid $gray-500;
    border-radius: 50%;
  }

  .panel {
    &.panel-table {
      min-height: 30.5rem;
      max-height: 42rem;
      padding: 2rem 3rem;

      .tabs-table-container {
        position: relative;
        overflow-y: auto;

        .tab-content {
          height: 100%;
        }

        > .tab-list,
        .pagination {
          position: sticky;
          z-index: 1;
          background-color: $white;
        }

        > .tab-list {
          top: 0;
        }

        .pagination {
          bottom: 0;
          height: 6rem;
        }

        table {
          white-space: nowrap;
        }
      }
    }
  }

  table tr th:nth-last-child(1) {
    color: transparent;
  }

  .table {
    .table-offers-list {
      .tokenid-col {
        min-width: 15rem;
        max-width: 15rem;
        color: $primary-blue;
      }

      .eth-col,
      .usd-col {
        min-width: 10rem;
        max-width: 10rem;
      }

      .provider-col,
      .buyer-col {
        min-width: 20rem;
        max-width: 20rem;
        color: $primary-blue;
      }

      .date-col {
        width: 100%;
      }

      .action-col {
        display: flex;
        max-width: 5rem;
        align-items: flex-end;
        justify-content: flex-end;
      }
    }
  }

  #offer-modal {
    min-width: unset;
    max-height: 95vh;

    @include small-desktop {
      min-width: 76.8rem;
      max-height: unset;
    }

    img.offer-img {
      width: 8rem;
      height: 8rem;
    }

    .table {
      width: 100%;

      @include small-desktop {
        width: 39rem;
      }
    }
  }

  #confirm-modal {
    display: flex;
    min-width: unset;
    height: 90vh;
    align-items: center;
    overflow-y: auto;

    @include small-desktop {
      min-width: 50rem;
      height: auto;
      max-height: 62rem;
    }
  }

  #processing-modal {
    img {
      animation: rotate 1s ease-in-out infinite;
    }
  }

  #offer-modal,
  #confirm-modal {
    .token {
      max-width: 21.8rem;
    }

    td p {
      font-size: 1.2rem;
    }
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
}
