.table {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  background-color: $white;
  border-radius: $border-radius-5;

  .table-container {
    &.banded {
      thead {
        tr {
          th {
            box-shadow: inset 0 -.1rem 0 #e7e7ed;
          }
        }
      }

      tbody {
        tr {
          &:nth-child(2n + 2) {
            background: rgba($primary-blue, .1);
          }
        }
      }
    }

    position: inherit;
    padding-bottom: 1rem;
    overflow-x: auto;

    table {
      width: 100%;
      font-family: $avenir-font;

      thead {
        tr {
          th {
            padding: 1rem;
            color: $gray-300;
            font-feature-settings: "smcp", "c2sc";
            font-size: $font-size-md;
            font-variant: all-small-caps;
            font-weight: 500;
            text-align: left;
            text-transform: uppercase;
          }
        }
      }

      tbody {
        tr {
          td {
            overflow: hidden;
            padding: 1rem 1.2rem;
            color: $dark;
            font-size: $font-size-sm;
            font-weight: 500;
            text-overflow: ellipsis;
            vertical-align: middle;

            span {
              cursor: pointer;
            }

            a {
              color: $primary-blue;
            }

            &.row-offer {
              display: flex;
              flex-direction: row;
              gap: 1rem;
            }
          }
        }
      }
    }
  }
}

.pagination {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
  gap: .5rem;

  button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: .2rem;
    border-radius: 100%;
    color: $primary-blue;
    cursor: pointer;
    transition: transform .3s ease-in-out, background-color .3s ease-in-out;

    &.keyboard-double-arrow-left,
    &.keyboard-double-arrow-right {
      transform: scale(.8);
    }

    &:disabled {
      cursor: not-allowed;

      span {
        color: $gray-400;
      }

      &:focus,
      &:hover {
        background-color: transparent;

        span {
          color: $gray-400;
        }
      }
    }
  }

  .pages-numbers {
    display: flex;
    flex-direction: row;
    gap: .5rem;

    .pagination-number {
      display: flex;
      width: 3rem;
      height: 3rem;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      cursor: pointer;
      transition: background-color .3s ease-in-out;

      span {
        color: $gray-400;
        font-family: $avenir-font;
        font-size: $font-size-md;
        font-weight: 500;
        line-height: 0;
      }

      &:focus,
      &:hover {
        background-color: rgba($primary-blue, .2);

        span {
          color: $primary-blue;
        }
      }

      &.pagination-active {
        background-color: rgba($primary-blue, .1);

        span {
          color: $primary-blue;
        }

        &:focus,
        &:hover {
          background-color: $active-primary-blue;

          span {
            color: $white;
          }
        }
      }
    }
  }
}

.table-row-anim-exit-active {
  animation: hide-row-from-table .7s ease-in-out forwards;
}

@keyframes hide-row-from-table {
  0% {
    opacity: 1;
    transform: scaleY(1);
    transform-origin: 50% 50%;
  }

  100% {
    opacity: 0;
    transform: scaleY(0);
    transform-origin: 50% 50%;
  }
}
