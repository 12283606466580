@include fade-from-side(error-from-side, -2rem, 0);

$select-item-height: 4rem;
$input-border: .2rem;

.dropdown-box {
  position: relative;
  cursor: pointer;
  -webkit-touch-callout: none;
  user-select: none;

  > .input-text {
    margin-bottom: .8rem;

    > label {
      margin-bottom: 1rem;
      color: $primary-blue;
      font-family: $avenir-font;
      font-size: $font-size-md;
      font-weight: 500;
    }

    > p {
      display: flex;
      overflow: hidden;
      margin-top: .8rem;
      -webkit-box-orient: vertical;
      color: $gray-300;
      font-size: $font-size-sm;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      text-overflow: ellipsis;
    }
  }

  .dropdown-selector {
    display: flex;
    width: 100%;
    height: 4rem;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    background-color: $white;
    border-radius: $border-radius-5;
    box-shadow: 0 0 0 $input-border $white-600;

    &:active {
      box-shadow: 0 0 0 $input-border $primary-blue;
      transition: box-shadow .2s ease-in-out;
    }

    &:hover {
      box-shadow: 0 0 0 $input-border $gray-400;
      transition: box-shadow .2s ease-in-out;
    }

    > label {
      padding-top: .5rem;
      color: $gray-300;
      font-size: $font-size-md;

      &.selected {
        color: $gray-300;
      }
    }

    > span {
      color: $primary-blue;
      transition: transform .2s ease-in-out;
    }
  }

  .dropdown-list {
    position: absolute;
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 0;
    max-height: unset;
    flex-direction: column;
    align-items: flex-start;
    background-color: $white;
    border-radius: $border-radius-5;
    box-shadow: 0 0 .5rem rgb(187 187 187 / .5);
    transform: translateY(0);
    transition: transform .5s ease-in-out;

    > button {
      width: 100%;
      height: $select-item-height;
      padding: 0 1rem;
      color: $gray-300;
      cursor: pointer;
      font-size: $font-size-md;
      text-align: left;
      vertical-align: middle;

      &:hover {
        background-color: rgba($primary-blue, .1);
      }
    }
  }

  &.dropdown-open {
    .dropdown-selector {
      span {
        transform: rotate(180deg);
      }

      &:hover {
        box-shadow: 0 0 0 $input-border $primary-blue;
        transition: .2s all ease-in-out;
      }
    }

    .dropdown-list {
      z-index: 50;
      height: auto;
      max-height: calc(6 * $select-item-height);
      transform: translateY(.3rem);
    }
  }

  &.error {
    > .dropdown-selector {
      margin-bottom: 1rem;
      box-shadow: 0 0 0 .1rem $secondary-red;

      &:hover {
        box-shadow: 0 0 0 $input-border $gray-400;
      }

      &:active {
        box-shadow: 0 0 0 $input-border $secondary-red;
      }

      &:focus {
        &::placeholder {
          color: transparent;
        }
      }
    }

    label.error {
      left: 0;
      width: 100%;
      padding: 1rem 1rem 0;
      animation: error-from-side .5s ease-in-out forwards;
      color: $secondary-red;
      font-family: $avenir-font;
      font-size: $font-size-sm;
    }
  }

  .input-icon {
    position: absolute;
    right: 0;
    bottom: .4rem;
    display: flex;
    width: 3rem;
    height: 3rem;
    box-sizing: border-box;
    align-items: center;
  }
}
