main.marketplace {
  height: calc(100vh - $navbar-height);
  background-color: $white-600;

  .filters {
    .filters-block-header {

      @include desktop {
        padding-top: 2rem;
      }
    }

    .filters-block-sections {

      @include desktop {
        position: sticky;
        top: 5rem;
      }

      .filter-section {
        display: block;
        margin-bottom: 1rem;

        @include desktop {
          background-color: $white-600;
        }

        .filter-header {
          padding: .625rem 0 .125rem;

          @include desktop {
            position: sticky;
            z-index: 990;
            top: 0;
            padding: 2.5rem 0 .5rem;
            background-color: $white-600;

            &::before {
              position: absolute;
              top: 4.7rem;
              left: 0;
              width: 100%;
              height: 1rem;
              background: linear-gradient(0deg, rgba($white-600, 0) 28%, rgba($white-600, 1) 100%);
              content: "";
            }
          }
        }

        .filter-section-paginated {
          top: 0;
          left: 0;
        }
      }
    }
  }

  .marketplace-panel {
    background-color: $white;
    border-radius: $border-radius-5;
    box-shadow: 0 1rem 1.5rem 0 rgb(205 205 205 / .35);

    &:-webkit-scrollbar-track {
      box-shadow: inset 0 0 .6rem $white-600;
    }

    &:-webkit-scrollbar-thumb {
      background-color: $white-600;
      outline: .1rem solid slategrey;
    }

    &.panel-background {
      background-color: transparent;
      box-shadow: unset;

      @include small-desktop {
        background-color: $white;
        box-shadow: 0 1rem 1.5rem 0 rgb(205 205 205 / .35);
      }
    }

    &.marketplace-panel-filter {
      top: 0;
      display: grid;
      grid-column: auto / span 12;
      grid-template-columns: repeat(12, 1fr);

      @include small-desktop {
        position: unset;
        top: unset;
        background-color: transparent;
        box-shadow: unset;
      }
    }

    &.marketplace-panel-sort {
      position: sticky;
      z-index: 998;
      top: 0;
      display: grid;
      padding: 2rem 1.5rem;
      grid-column: auto / span 12;
      grid-template-columns: repeat(12, 1fr);

      @include small-desktop {
        position: unset;
        top: unset;
        background-color: transparent;
        box-shadow: unset;
      }
    }

    &.panel-tokens {

      @include small-desktop {
        background-color: transparent;
        box-shadow: unset;
      }
    }
  }

  .marketplace-tokens-grid {
    position: relative;
    display: grid;
    align-self: center;
    gap: 3rem 1rem;
    grid-template-columns: repeat(1, 1fr);

    @include tablet {
      grid-template-columns: repeat(2, 1fr);
    }

    @include desktop {
      grid-template-columns: repeat(3, 1fr);
    }

    @include large-desktop {
      grid-template-columns: repeat(5, 1fr);
    }

    @include resolution(1600px) {
      grid-template-columns: repeat(6, 1fr);
    }
  }
}
