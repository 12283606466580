main.home {
  min-height: calc(100vh - $navbar-height);
  overflow-x: hidden;

  #about-us, #create-collection {
    scroll-margin-top: 15rem;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  @include small-desktop {
    min-height: calc(100vh - ($navbar-height + $footer-tablet-height));
  }

  @include large-desktop {
    min-height: calc(100vh - ($navbar-height + $footer-height));
  }

  .hero {
    .hero-container {
      z-index: 50;
      margin-top: 15rem;
      place-items: center;

      .hero-text {

        @include desktop {
          margin-left: 2rem;
        }

        p {
          width: 100%;
          line-height: 3rem;

          @include desktop {
            max-width: 57rem;
          }
        }
      }
    }

    .centered-grid {
      display: grid;
      width: 80%;
      margin-right: auto;
      margin-left: auto;
    }

    .text-gradient {
      background: linear-gradient(89.81deg, #5C98EF 5.34%, #63CDC0 73.43%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .explore-mint-enjoy {
      min-height: 10rem;
      background: $white;
      border-radius: 6.25rem;
      box-shadow: 0 .625rem .938rem rgba(205 205 205 / .35);
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, 1fr);

      @include tablet {
        max-width: 100%;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
      }

      .item {
        display: grid;
        padding: 5rem;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, 1fr);
        text-align: center;

        @include small-desktop {
          padding: 0;
        }
      }
    }

    .about-us {
      padding-bottom: 5rem;
      grid-gap: 5rem 15rem;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, 1fr);

      @include small-desktop {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
      }

      .first-title {
        font-variant: all-small-caps;
        letter-spacing: .2em;
      }

      .tokens {
        align-items: center;
        grid-gap: 10rem 8rem;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, 1fr);
        justify-items: center;

        @include tablet {
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: 1fr;
        }

        .token:hover {
          transform: none;
        }

        .zoomed, .zoomed:hover {
          transform: scale(1.5);
        }
      }
    }

    .create-collection {
      padding-bottom: 5rem;
      grid-gap: 5rem 15rem;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, 1fr);

      @include large-desktop {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
      }

      .first-title {
        font-variant: all-small-caps;
        letter-spacing: .2em;
      }
    }

    .featured-artists {
      .first-title {
        color: $gray-400;
        font-size: 1.125rem;
        font-style: normal;
        font-variant: all-small-caps;
        font-weight: 800;
        letter-spacing: .2em;
        line-height: 105%;
      }

      .second-title {
        font-size: 3rem;
        font-weight: 700;
        letter-spacing: .002em;
        line-height: 120%;
      }
    }
  }
}
