$cover-header-height: 20.5rem;
$cover-header-mobile-height: 18.2rem;

.artist-slider-card {
  > .full-area {
    position: relative;
    opacity: 1;

    .hero-banner {
      overflow: hidden;
      height: $cover-header-mobile-height;
      cursor: pointer;

      &.has-hero-cover {
        &::before {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 10%;
          content: "";
          transition: height .25s ease-in-out;
        }

        > img {
          transition: transform .25s ease-in-out;
        }

        &:hover {
          &::before {
            height: 50%;
          }

          > img {
            transform: scale(1.05);
          }
        }
      }

      @include small-desktop {
        height: $cover-header-height;
      }

      .no-cover {
        height: 100%;
        background-color: $gray-500;
      }

      > img {
        top: 0;
        width: 100%;
        height: 100%;
        background-color: $gray-500;
        object-fit: cover;
      }

      &:hover {
        background-color: rgba($white, .65);
        opacity: 1 !important;

        &:hover {
          background-color: rgba($white, .95);
          transition: background-color, min-width 1.5s ease-in-out;

          > span {
            display: flex;
            align-self: center;
            padding: 0 2rem 0 1rem;
            opacity: 1 !important;
          }
        }
      }
    }

    .artist-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding: 0 7.5rem;
      pointer-events: none;
      transform: translateY(-4rem);

      @include small-desktop {
        flex-direction: row;
        transform: translateY(-4rem) translateX(-5rem);
      }

      .artist-avatar {
        width: 9rem;
        height: 9rem;
        border-color: $white-600;

        @include small-desktop {
          width: 7.5rem;
          height: 7.5rem;
        }
      }

      .artist-info {

        @include small-desktop {
          align-self: flex-end;
        }
      }
    }
  }
}
