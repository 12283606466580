/*
--------------------------------------------------------------------------------
Configuration sizes for margins & paddings
--------------------------------------------------------------------------------
*/

$spacing-10: 8rem;
$spacing-9: 7.2rem;
$spacing-8: 6.4rem;
$spacing-7: 5.6rem;
$spacing-6: 4.8rem;
$spacing-5: 4rem;
$spacing-4: 3.2rem;
$spacing-3: 2.4rem;
$spacing-2: 1.6rem;
$spacing-1: .8rem;
$navbar-height: 7.8rem;

/*
--------------------------------------------------------------------------------
Borders
--------------------------------------------------------------------------------
*/

$border-radius-5: .5rem;
$border-radius-10: 1rem;
$border-radius-15: 1.5rem;
$border-radius-20: 2rem;

/*
--------------------------------------------------------------------------------
Spreadmint Color Paletts
--------------------------------------------------------------------------------
*/

$primary-mint: #23c2a6;
$primary-blue: #4f74f6;
$active-primary-mint: #0ca69d;
$active-primary-blue: #2d28df;
$secondary-mint: #14aea5;
$secondary-blue: #5a87fd;
$secondary-dark-blue: #18206c;
$primary-electric-blue: #544ff6;
$secondary-red: #ec4559;
$active-secondary-red: #da3d50;
$white: #fff;
$white-100: #F2F2F2;
$white-500: #e5e5e5;
$white-600: #f6f6f6;
$white-900: #f9f9f9;
$gray-200: #6d6d6d;
$gray-300: #828282;
$gray-400: #bdbdbd;
$gray-500: #e0e0e0;
$gray-600: #ededed;
$sec-gray: #6E6E6E;
$dark: #23223b;
$black: #000;
$gradient-teal-mint: linear-gradient(89.81deg, #5c98ef 5.34%, #63cdc0 73.43%);
$gradient-pearl: linear-gradient(
  126.72deg,
  rgb(35 194 166 / 0) 13.56%,
  rgb(35 194 166 / .3) 48.37%,
  rgb(35 194 166 / 0) 86.2%
);

/*
--------------------------------------------------------------------------------
Fonts
--------------------------------------------------------------------------------
*/

$archivo-font: "Archivo", sans-serif;
$avenir-font: "AvenirNext", sans-serif;
$nunito-font: "Nunito Sans", sans-serif;
$montserrat-font: "Montserrat", sans-serif;
$material-icons-font-size: 1.8rem !default;
$font-size-xxxl: 6.4rem;
$font-size-xxl: 3.6rem;
$font-size-xl: 2.4rem;
$font-size-lg: 1.8rem;
$font-size-md: 1.6rem;
$font-size-sm: 1.4rem;
$line-height-base: 1.5;
$line-height-sm: 1.25;
$line-height-lg: 2;
