@use "sass:math";

/*
--------------------------------------------------------------------------------
Theme Colors utilities
--------------------------------------------------------------------------------
*/

$colors: (
  "primary-mint": $primary-mint,
  "primary-blue": $primary-blue,
  "primary-electric-blue": $primary-electric-blue,
  "active-primary-blue": $active-primary-blue,
  "active-primary-mint": $active-primary-mint,
  "secondary-mint": $secondary-mint,
  "secondary-blue": $secondary-blue,
  "secondary-dark-blue": $secondary-dark-blue,
  "secondary-red": $secondary-red,
  "white": $white,
  "white-100": $white-100,
  "white-500": $white-500,
  "white-600": $white-600,
  "white-900": $white-900,
  "gray-200": $gray-200,
  "gray-300": $gray-300,
  "gray-400": $gray-400,
  "gray-500": $gray-500,
  "gray-600": $gray-600,
  "sec-gray": $sec-gray,
  "dark": $dark,
  "black": $black,
);
$gradients: (
  "teal-mint" $gradient-teal-mint,
  "pearl" $gradient-pearl
);

:root {

  @each $colorkey, $color in $colors {
    --#{$colorkey}: #{$color};
  }
}

body {
  $rounded: 5, 8, 15;

  @each $round-val in $rounded {
    .rounded-#{$round-val} {
      border-radius: #{$round-val * .1}rem !important;
    }
  }

  @each $colorkey, $color in $colors {
    .color-#{$colorkey} {
      color: $color;
    }
  }

  @each $colorkey, $color in $colors {
    .bg-#{$colorkey} {
      background-color: $color;
    }
  }

  @each $gradientkey, $gradient in $gradients {
    .bg-grad-#{$gradientkey} {
      background: $gradient;
    }
  }

  /*
  --------------------------------------------------------------------------------
  Theme fonts utilities
  --------------------------------------------------------------------------------
  */

  $font-families: (
    nunito: $nunito-font,
    avenir: $avenir-font,
    archivo: $archivo-font,
    montserrat: $montserrat-font,
  );
  $font-sizes: 1.2, 1.4, 1.6, 1.8, 2.2, 2.4, 3.6, 4.8, 6.4;
  $font-weights: 100, 200, 300, 400, 500, 600, 700, 800, 900;

  @each $font-size in $font-sizes {
    .f-#{calc($font-size * 10)} {
      font-size: #{$font-size}rem;
    }

    @each $breakpoint-key, $breakpoint-values in $grid-breakpoints {

      @include media-breakpoint-only($breakpoint-key) {
        .f-#{$breakpoint-key}-#{calc($font-size * 10)} {
          font-size: #{$font-size}rem !important;
        }
      }
    }
  }

  @each $font-tag, $font-declaration in $font-families {

    @each $weight in $font-weights {
      .#{$font-tag}-#{$weight} {
        font-display: swap;
        font-family: $font-declaration;
        font-weight: #{$weight};
      }

      @each $breakpoint-key, $breakpoint-values in $grid-breakpoints {

        @include media-breakpoint-only($breakpoint-key) {
          .#{$font-tag}-#{$breakpoint-key}-#{$weight} {
            font-display: swap;
            font-family: $font-declaration;
            font-weight: #{$weight};
          }
        }
      }
    }
  }

  .truncate {
    overflow: hidden;
    text-overflow: ellipsis;

    @for $i from 1 through 10 {
      &.max-line-#{$i} {
        display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
        -webkit-box-orient: vertical;
        -webkit-line-clamp: #{$i};
      }
    }
  }

  .small-caps {
    font-feature-settings: "smcp", "c2sc";
    font-variant: all-small-caps;

    // font rendering issue with the small caps variant
    // target safari only: https://www.bram.us/2021/06/23/css-at-supports-rules-to-target-only-firefox-safari-chromium/

    @supports selector(:nth-child(1 of x)) {
      font-variant: unset;
    }
  }

  a {
    cursor: pointer;
    font-size: $font-size-sm;
    text-decoration: none;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  main {
    position: relative;
    margin-top: $navbar-height;
    background-color: $white-600;
    overflow-y: auto;

    @include small-desktop {
      padding: unset;
    }
  }

  hr {
    width: 100%;
    border: 0;
    border-top: .1rem solid $gray-500;
  }

  .gradient-text {
    background-clip: text;
    color: transparent;
  }

  .prevent-select {
    user-select: none;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    span,
    img {
      user-select: none;
    }
  }

  .panel {
    background-color: $white;
    border-radius: $border-radius-10;
    box-shadow: 0 1rem 1.5rem 0 rgb(205 205 205 / .35);

    &:-webkit-scrollbar-track {
      box-shadow: inset 0 0 .6rem $white-600;
    }

    &:-webkit-scrollbar-thumb {
      background-color: $white-600;
      outline: .1rem solid slategrey;
    }

    @include large-desktop {
      &.panel-left-radius {
        border-radius: 1rem 0 0 1rem;
      }

      &.panel-right-radius {
        border-radius: 0 1rem 1rem 0;
      }

      &.panel-top-left-radius {
        border-radius: 1rem 0 0;
      }

      &.panel-bottom-left-radius {
        border-radius: 0 0 0 1rem;
      }

      &.panel-top-right-radius {
        border-radius: 0 1rem 0 0;
      }

      &.panel-bottom-right-radius {
        border-radius: 0 0 1rem;
      }
    }
  }
}
