.authentication-page {
  display: flex;
  height: calc(100vh - $navbar-height);
  flex-direction: column;
  justify-content: center;
  gap: 3rem;
  place-items: center;

  @include small-desktop {
    justify-content: flex-start;
  }
}
