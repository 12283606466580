$tier-card-desktop: 30.6rem;

main.artist {
  overflow: auto;
  width: 100vw;
  min-height: 100vh;
  background-color: $white-600;
  overflow-y: unset;

  .artist-header {
    height: 36rem;

    @include small-desktop {
      height: 43rem;
    }

    > .full-area {
      position: relative;
      opacity: 1;

      .parallax-container {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 20rem;

        @include small-desktop {
          height: 30rem;
        }

        .parallax-banner {
          height: 30rem;
          background-position: bottom;
          background-repeat: no-repeat;
          background-size: cover;

          &.has-banner {
            background-position: center;
          }

          @include small-desktop {
            height: 40rem;
          }
        }
      }

      .artist-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding: 0 4.5rem;
        margin: 0 auto;
        pointer-events: none;
        transform: translateY(-4rem);

        @include small-desktop {
          flex-direction: row;
          transform: translateY(-9rem);
        }

        .artist-avatar {
          width: 9rem;
          height: 9rem;
          border-color: $white-600;

          @include small-desktop {
            width: 18rem;
            height: 18rem;
          }
        }

        .artist-info {

          @include small-desktop {
            align-self: flex-end;
          }
        }
      }
    }

    > .sticky-area {
      position: fixed;
      z-index: 5;
      top: 0;
      width: 100%;
      padding: .25rem 1rem;
      backdrop-filter: blur(.5rem) saturate(80%);
      background-color: rgba($white, .95);
      filter: drop-shadow(0 0 .75rem $gray-400);
      opacity: 0;
      transition: ease-in-out .2s;

      .artist-name {
        max-width: auto;

        @include small-desktop {
          max-width: unset;
        }
      }
    }
  }

  .custom-grid {
    position: relative;
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;

    > .token-mint-container {
      position: relative;
      width: 100%;
      order: 1;
    }

    > .artist-stats-container {
      position: relative;
      width: 100%;
      order: 2;
    }

    @include small-desktop {
      grid-template-columns: 1fr 24.7rem;

      > .artist-stats-container {
        order: 1;
      }

      > .token-mint-container {
        order: 2;
      }
    }
  }

  .custom-bottom-grid {
    display: grid;
    grid-template-columns: 1fr;

    @include small-desktop {
      grid-template-columns: 25rem 1fr 24.7rem;
    }
  }

  .manager-token-price {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: .5rem;
    gap: .5rem;

    > img {
      padding: .25rem;
      border: .1rem solid $gray-500;
      border-radius: 50%;
    }
  }

  .filters-wrapper {
    .tiers-filters {

      @include small-desktop {
        margin-top: 4rem;
      }
    }

    @include small-desktop {
      position: sticky;
      top: 19rem;
      max-height: 50rem;
    }
  }

  .tiers-section {
    position: sticky;
    top: 0;
  }

  .tier-flip-card {
    position: relative;
    height: $tier-card-desktop;
    background-color: transparent;
    border-radius: unset;
    box-shadow: unset;
    transform-style: preserve-3d;
    transition: .6s .1s;

    &:-webkit-scrollbar-track {
      box-shadow: inset 0 0 .6rem $white-600;
    }

    &:-webkit-scrollbar-thumb {
      border: 1rem solid slategrey;
      background-color: $white-600;
    }

    @include small-desktop {
      background-color: $white;
      border-radius: $border-radius-10;
      box-shadow: 0 1rem 1.5rem 0 rgb(205 205 205 / .35);
    }

    &:hover,
    &:focus-within {
      transform: rotateY(180deg);
    }

    .front,
    .back {
      position: absolute;
      top: 0;
      left: 0;
      display: inherit;
      display: flex;
      overflow: hidden;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      backface-visibility: hidden;
      border-radius: 1rem;
    }

    .front {
      z-index: 2;
      height: 100%;
      justify-content: space-between;
      transform: rotateY(0deg);
    }

    .back {
      z-index: 1;
      align-items: start;
      background-color: $primary-blue;
      gap: 3;
      text-align: left;
      transform: rotateY(180deg);

      ul {
        margin-left: 1.5rem;

        li {
          list-style-type: disc;
        }
      }

      button {
        border: .1rem solid $white;
        background-color: transparent;
        color: $white;

        &:hover,
        &:focus {
          background-color: $white;
          box-shadow: 0 0 .4rem $white;
          color: $primary-blue;
        }
      }
    }

    .tier-tokens-pulled {
      display: flex;
      width: auto;
      flex-direction: row;
      background-color: rgba($active-primary-blue, .14);
      border-radius: 2.2rem;
    }
  }

  .panel-tiers {
    > .collapse-panel {
      max-height: calc(($tier-card-desktop + 3rem) * 3);

      &.collapsed {
        max-height: 0;
        opacity: 0;
      }
    }
  }

  .modal {
    &#mint-information {
      position: relative;
      max-height: 80vh;
      box-sizing: border-box;

      .content {
        position: relative;
        display: flex;
        overflow: hidden;
        width: 100%;
        height: 100%;
        max-height: calc(80vh - 20rem);
        box-sizing: border-box;
        flex-direction: column;
        align-self: start;

        .copy {
          position: relative;
          display: flex;
          overflow: auto;
          width: 100%;
          height: 100%;
          flex-direction: column;
          padding: 2rem;
          gap: 2rem;
        }

        &::before,
        &::after {
          position: absolute;
          z-index: 1;
          left: 0;
          width: calc(100% - 1rem);
          height: 3rem;
          content: "";
        }

        &::before {
          top: 0;
          background: rgb(255 255 255);
          background: linear-gradient(180deg, rgb(255 255 255 / 1) 0%, rgb(255 255 255 / 0) 100%);
        }

        &::after {
          bottom: 0;
          background: rgb(255 255 255);
          background: linear-gradient(0deg, rgb(255 255 255 / 1) 0%, rgb(255 255 255 / 0) 100%);
        }
      }
    }

    &#mint-confirm {

      @include small-desktop {
        width: 51.6rem;
        padding: 1.5rem 5rem;
      }
    }

    &#mint-success {
      overflow: auto;
      max-height: 90vh;

      @include small-desktop {
        width: 51.6rem;
        padding: 1.5rem 5rem;
      }

      .token-wrapper {
        width: 17.2rem;
        height: 20rem;
      }
    }
  }
}
