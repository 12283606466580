@include fade-from-side(error-from-side, -2rem, 0);

$input-border: .2rem;
$open-transition: 1s;
$section-row: 6.4rem;

.search-input-group {
  right: 0;
  display: flex;
  width: 100%;
  flex-direction: column;
  transition: width $open-transition ease-in-out;

  > .input {
    position: relative;
    width: 100%;
    height: 4rem;
    color: $dark;
    transition: width $open-transition ease-in-out;

    > input {
      width: 100%;
      height: 4rem;
      padding: 0 $spacing-4 0 $spacing-1;
      background-color: $white;
      border-radius: $border-radius-5;
      box-shadow: 0 0 0 .1rem $white-600;
      font-size: $font-size-md;

      &::placeholder {
        color: $dark;
      }

      &:focus,
      &:focus-within,
      &:hover {
        box-shadow: 0 0 0 $input-border $primary-blue;
        transition: .2s box-shadow ease-in-out;
      }
    }
  }

  .input-icon {
    position: absolute;
    right: 0;
    bottom: .4rem;
    display: flex;
    width: 3rem;
    height: 3rem;
    box-sizing: border-box;
    align-items: center;
    color: $secondary-blue;
    cursor: pointer;
    transform: scale(1.2);
  }

  &.disabled {
    input {
      cursor: not-allowed;

      &:focus,
      &:focus-within,
      &:hover {
        box-shadow: 0 0 0 .1rem transparent;
        transition: .2s box-shadow ease-in-out;
      }

      &::placeholder {
        color: rgba($dark, .4);
      }
    }

    .input-icon {
      color: rgba($secondary-blue, .4);
    }
  }

  &.style-dark {
    .input {
      > input {
        background-color: #373656;
        box-shadow: 0 0 0 .1rem transparent;
        color: $white;
        transition: color .5s ease-in-out;

        &::placeholder {
          color: rgba($white-600, .5);
        }

        &:focus,
        &:focus-within,
        &:hover {
          box-shadow: 0 0 0 $input-border $primary-blue;
          transition: box-shadow .2s ease-in-out;
        }
      }
    }

    .input-icon {
      color: $primary-mint;
    }

    &.disabled {
      input {
        cursor: not-allowed;

        &::placeholder {
          color: rgba($white-600, .4);
        }

        &:focus,
        &:focus-within,
        &:hover {
          box-shadow: 0 0 0 .1rem transparent;
          transition: .2s box-shadow ease-in-out;
        }
      }

      .input-icon {
        color: rgba($primary-mint, .4);
      }
    }
  }

  &.min {
    .search-input-group {
      align-items: flex-end;
      transition: width $open-transition ease-in-out;
    }

    .input {
      input {
        width: 0;
        background-color: transparent;
        transition: width $open-transition ease-in-out;

        &:focus,
        &:focus-within,
        &:hover {
          box-shadow: 0 0 0 .1rem transparent;
        }
      }
    }

    .input-icon {
      right: .5rem;
      color: $white;
      font-size: $font-size-xl;
      transform: scale(1);
      transition: transform .5s ease-in-out;
    }

    &.search-open {
      .input {
        input {
          width: calc(100vw - 13rem);
          box-shadow: 0 0 0 $input-border $primary-blue;
          color: $white;
          transition: $open-transition width ease-in-out;
        }

        .input-icon {
          right: .3rem;
          color: $white;
          font-size: $font-size-xl;
          transform: scale(.7);
        }
      }
    }
  }

  .search-results {
    position: absolute;
    top: 7rem;
    width: 100%;
    height: 0;
    padding: 1.2rem;
    background-color: white;
    border-radius: .5rem;
    box-shadow: 0 1rem 1.5rem 0 rgb(27 27 27 / .25);
    visibility: hidden;

    > .loader-area,
    > .no-results {
      display: flex;
      height: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &.open {
      height: auto;
      max-height: calc(100vh - $navbar-height - 2rem);
      overflow-y: auto;
      visibility: visible;

      .loader-area {
        min-height: 4.5rem;
      }

      .has-results {
        height: auto;
        max-height: calc(10 * $section-row + (2 * 4rem) + 7rem);
      }

      .no-results {
        height: auto;

        @include small-desktop {
          align-items: flex-start;
        }
      }
    }

    &.min {
      z-index: 999;
      left: -6.3rem;
      width: calc(100vw - 2rem);
      height: calc(95vh - $navbar-height);
    }

    section {
      margin-bottom: 1rem;

      &:nth-last-child(1) {
        margin-bottom: 0;
      }

      > h1 {
        padding: .845rem 1.135rem;
        border-radius: 1rem;
      }

      .section-row {
        height: $section-row;
        align-items: center;
        padding: 0 1rem;
        border-radius: 1rem;
        transition: background .5s ease-in-out;

        &:hover {
          background-color: rgba($gray-500, .2);
        }

        .avatar-placement {
          width: 3.5rem;
        }
      }
    }
  }
}
