@include translate-up-down(translate-up, 0, 200px);

.background-cards-bg {
  position: absolute;
  overflow: hidden;
  width: 100vw;
  height: 45%;
  background: $white-600;

  &::before {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba($white-600, 1) 40%, rgba($white-600, .8), rgba($white-600, .6));
    content: "";

    @include small-desktop {
      background: linear-gradient(15deg, rgba($white-600, 1) 15%, rgba($white-600, .3) 50%, rgba($white-600, 0));
    }
  }

  .background-cards-container {
    position: absolute;
    top: -10%;
    right: -20%;
    display: grid;
    width: 60%;
    height: 100%;
    column-gap: 2rem;
    grid-template-columns: repeat(2, 1fr);
    transform: rotate(14deg);

    @include tablet {
      grid-template-columns: repeat(3, 1fr);
    }

    @include small-desktop {
      top: -20%;
      right: -5%;
      width: 55%;
      grid-template-columns: repeat(4, 1fr);
    }

    .background-card-col {
      display: flex;
      flex-direction: column;

      @for $i from 1 through 4 {
        &:nth-child(#{$i}) {
          animation: translate-up
            25000ms
            linear
            calc(.4s - .6s / (#{$i - 10} / #{$i - 10}) + #{$i - 10} * 15s)
            infinite
            alternate
            forwards;
          transform-box: fill-box;
          transform-origin: center;
        }
      }

      .artist-card {
        width: 100%;
        height: 100%;
        min-height: 10rem;

        &:not(:last-child) {
          margin-bottom: 2rem;
        }

        > img {
          object-fit: cover;
        }

        @include small-desktop {
          min-height: 18rem;
        }

        @include large-desktop {
          min-height: 25rem;
        }
      }
    }
  }
}
