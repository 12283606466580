.image-container {
  position: relative;
  display: flex;
  overflow: hidden;
  min-width: 2rem;
  min-height: 2rem;
  background-color: rgba($gray-500, .5);

  /* fix transform avatar out of bounds on safari -> https://greensock.com/forums/topic/15105-bizzare-behaviour-from-overflowhidden/ */
  transform: translateZ(0);

  &.square {
    border-radius: $border-radius-5;
  }

  &.round {
    border-radius: 50%;
  }

  > img {
    width: 100%;
    height: 100%;
    filter: blur(0);
    object-fit: contain;
    opacity: 1;
    transform: scale(1);
    transition: opacity .3s ease-in-out, filter, transform .7s ease-in-out;
  }

  &.blurred {
    > img {
      filter: blur(.5rem);
      opacity: 0;
      transform: scale(1.1);
    }
  }

  &.skeleton {
    &::before {
      position: absolute;
      width: 100%;
      height: 100%;
      animation: shimmer 3s linear infinite;
      background: linear-gradient(0deg, rgba($gray-500, 0) 25%, rgba($white, .25) 50%, rgba($gray-500, 0) 75%);
      content: "";
    }
  }

  .fallback {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@keyframes shimmer {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(100%);
  }
}
