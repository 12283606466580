@include fade-from-above(fade-up-warn, -50px, 0);

@include fade-from-above(fade-down-warn, 50px, 0);

.banner {
  &.banner-info {
    background-color: rgba($primary-electric-blue, .1);

    span.material-icons,
    p {
      color: $primary-electric-blue;
    }
  }

  &.banner-warning {
    background-color: rgba($secondary-red, .1);

    span.material-icons,
    p {
      color: $secondary-red;
    }
  }

  p b,
  p i,
  p span {
    line-height: 0;
  }
}

.animate-up {
  animation: fade-up-warn .5s linear forwards;
}

.animate-down {
  animation: fade-down-warn .5s linear forwards;
}
