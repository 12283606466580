main.marketplace-search-results {
  height: calc(100vh - $navbar-height);
  background-color: $white-600;

  .filters {
    position: sticky;
    top: 0;
    margin-bottom: 1.5rem;

    @include small-desktop {
      max-height: 100vh;
      overflow-y: auto;
    }
  }

  .marketplace-panel {
    background-color: $white;
    border-radius: $border-radius-5;
    box-shadow: 0 1rem 1.5rem 0 rgb(205 205 205 / .35);

    &:-webkit-scrollbar-track {
      box-shadow: inset 0 0 .6rem $white-600;
    }

    &:-webkit-scrollbar-thumb {
      background-color: $white-600;
      outline: .1rem solid slategrey;
    }

    &.panel-background {
      background-color: transparent;
      box-shadow: unset;

      @include small-desktop {
        background-color: $white;
        box-shadow: 0 1rem 1.5rem 0 rgb(205 205 205 / .35);
      }
    }

    &.marketplace-panel-filter {
      top: 0;
      display: grid;
      grid-column: auto / span 12;
      grid-template-columns: repeat(12, 1fr);

      @include small-desktop {
        position: unset;
        top: unset;
        background-color: transparent;
        box-shadow: unset;
      }
    }
  }

  .marketplace-tokens-grid {
    position: relative;
    display: grid;
    align-self: center;
    gap: 3rem 1rem;
    grid-template-columns: repeat(1, 1fr);

    @include tablet {
      grid-template-columns: repeat(2, 1fr);
    }

    @include desktop {
      grid-template-columns: repeat(3, 1fr);
    }

    @include large-desktop {
      grid-template-columns: repeat(5, 1fr);
    }

    @include resolution(160rem) {
      grid-template-columns: repeat(6, 1fr);
    }
  }
}
