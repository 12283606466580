nav {
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  left: 0;
  height: $navbar-height;
  padding: 0 1.5rem;
  background-color: $dark;

  @include desktop {
    padding: 0 2.5rem 0 5rem;
  }

  > .grid {
    width: 100%;
    height: $navbar-height;

    .menu-column-1 {
      display: flex;

      > .grid {
        width: 100%;
      }
    }

    .menu-column-2 {
      display: flex;
      gap: 1rem;
      justify-self: end;

      @include desktop {
        justify-content: flex-end;
      }
    }
  }

  .hamburguer-menu {
    display: flex;
    width: 2.2rem;
    flex-direction: column;
    margin-left: 1rem;
    cursor: pointer;
    gap: .4rem;

    @include desktop {
      display: none;
    }

    .line {
      height: .3rem;
      background-color: $white;
      transition: all .2s ease-in-out;

      &:nth-child(2) {
        display: flex;
        width: 80%;
        align-self: flex-end;
      }
    }

    &.hamburguer-menu-open {
      .line {
        &:nth-child(1) {
          transform: rotate(45deg) translateX(.5rem) translateY(.5rem);
        }

        &:nth-child(2) {
          width: 0;
        }

        &:nth-child(3) {
          transform: rotate(-45deg) translateX(.5rem) translateY(-.5rem);
        }
      }
    }
  }

  ul.menu-options {
    position: absolute;
    top: 6rem;
    right: 0;
    left: 0;
    overflow: hidden;
    max-height: 0;
    background-color: $dark;
    transition: all 1s ease-in-out;
    visibility: hidden;

    @include desktop {
      position: relative;
      top: unset;
      right: unset;
      left: unset;
      display: flex;
      height: 100%;
      max-height: 100%;
      flex-direction: row;
      align-items: center;
      visibility: visible;
    }

    &:not(.menu-options-open) {
      > li {
        opacity: 0;
      }
    }

    > li {
      position: relative;
      display: flex;
      min-width: 13rem;
      justify-content: center;
      padding: 1rem 0;
      margin-top: 2rem;
      color: rgba($white-600, .75);
      cursor: pointer;
      text-align: center;

      @for $i from 1 through 4 {
        &:nth-child(#{$i}) {
          animation-fill-mode: both;
          transition: opacity .8s ease-in-out calc(.1s * #{$i});
        }
      }

      @include desktop {
        display: flex;
        height: 100%;
        align-items: center;
        margin-top: 0;

        @for $i from 1 through 4 {
          &:nth-child(#{$i}) {
            opacity: 1;
          }
        }
      }

      &::before {

        @include desktop {
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 0%;
          height: .4rem;
          background-color: $primary-mint;
          border-top-left-radius: $border-radius-5;
          border-top-right-radius: $border-radius-5;
          content: "";
          transition: .2s all ease-in-out;
        }
      }

      &.item-active {
        color: rgba($white-600, 1);

        &::before {

          @include desktop {
            left: 10%;
            width: 80%;
          }
        }
      }

      &:hover {
        color: $white-600;

        &:not(.auth-button) {
          &::before {

            @include desktop {
              left: 10%;
              width: 80%;
            }
          }
        }
      }

      &.auth-button,
      &.user-details {
        width: 100%;
        order: 99;

        @include desktop {
          justify-content: flex-end;
          margin-left: 6.5rem;
        }
      }

      &.user-details {
        position: relative;
        display: flex;
        gap: 2rem;

        > span {
          display: none;

          @include tablet {
            display: flex;
          }

          overflow: hidden;
          max-width: 15rem;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        @include small-desktop {
          .navbar-dropdown {
            position: fixed;
            z-index: 999;
            top: calc($navbar-height - 1rem);
            display: flex;
            justify-content: flex-end;

            > .navbar-dropdown-list {
              position: relative;
              z-index: 999;
              top: .5rem;
              right: 0;
              display: flex;
              width: 100%;
              min-width: 17rem;
              max-height: 0;
              flex-direction: column;
              align-self: flex-end;
              padding: .5rem;
              background: $white;
              border-radius: $border-radius-10;
              box-shadow: 0 0 .5rem rgb(187 187 187 / .5);
              opacity: 0;
              pointer-events: none;
              transform: translateY(0);
              transition: transform .5s ease-in-out, opacity .25s ease-in-out, max-height .65s ease-in-out;

              ul li {
                width: 100%;
                padding: 1rem;
                background-size: 100%;
                border-radius: $border-radius-5;
                cursor: pointer;
                opacity: 0;
                text-align: end;
              }
            }

            &.open {
              > .navbar-dropdown-list {
                width: max-content;
                height: auto;
                max-height: 25rem;
                opacity: 1;
                pointer-events: all;
                transform: translateY(1rem);

                ul li {

                  @for $i from 1 through 6 {
                    &:nth-child(#{$i}) {
                      animation-fill-mode: forwards;
                      opacity: 1;
                      transition: opacity .2s ease-in-out calc(.3s - .15s / (#{$i} * #{$i}) + #{$i} * .1s);
                    }
                  }

                  &:hover {
                    background: rgba($primary-blue, .05);
                    color: $primary-blue;

                    &::before {
                      content: unset;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.menu-options-open {
      max-height: 40rem;
      padding: 2rem 0 3rem;
      visibility: visible;

      li {

        @include desktop {
          opacity: 1;
        }
      }

      @include desktop {
        padding: 0;
      }
    }

    &.menu-options-closed {
      max-height: 0;
      visibility: hidden;

      li {

        @for $i from 1 through 4 {
          &:nth-child(#{$i}) {
            animation-delay: calc(.4s - .6s / (#{$i} * #{$i}) + #{$i} * .2s);
            animation-fill-mode: backwards;
            opacity: 1;
            transition: opacity .4s ease-in-out calc(.8s - .6s / (#{$i} * #{$i}) + #{$i} * .2s);
          }
        }
      }
    }
  }

  &.open-search {
    .logo {
      clip-path: polygon(0 0, 35% 0, 35% 100%, 0% 100%);

      .logo-letter {

        @for $i from -1 through 11 {
          &:nth-child(#{$i}) {
            opacity: 0;
            transition: opacity .2s ease-in-out calc(.2s / #{$i});
          }
        }
      }
    }
  }
}
