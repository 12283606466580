.favorite-icon {
  position: absolute;
  top: .5rem;
  right: 1rem;
  width: 4rem;
  height: 4rem;

  .favorite,
  .unfavorite {
    position: absolute;
    transition-duration: .25s;
    transition-property: opacity, transform;
    transition-timing-function: ease-in-out;
  }
}

.show-favorite {
  > .favorite {
    color: $primary-mint;
    opacity: 1;
    transform: scale(1) rotate(0deg) translate(0, 0);
  }

  > .unfavorite {
    opacity: 0;
    transform: scale(.9);
  }
}

.hide-favorite {
  > .favorite {
    color: $primary-mint;
    opacity: 0;
    transform: scale(.5) rotate(25deg) translate(1rem, -4rem);
  }

  > .unfavorite {
    opacity: 1;
    transform: scale(1);
  }
}

.show-favorite-background {
  > .favorite {
    opacity: 1;
    transform: scale(1) rotate(0deg) translate(0, 0);
  }

  > .unfavorite {
    opacity: 0;
    transform: scale(.9);
  }
}

.hide-favorite-background {
  > .favorite {
    opacity: 0;
    transform: scale(.5) rotate(25deg) translate(1rem, -4rem);
  }

  > .unfavorite {
    opacity: 1;
    transform: scale(1);
  }
}

.favorite-icon-background {
  position: relative;
  top: unset;
  right: unset;
  width: 4rem;
  height: 4rem;
}
