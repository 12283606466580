$footer-height: 23.6rem;
$footer-tablet-height: 28rem;

footer {
  position: relative;
  bottom: 0;
  overflow: hidden;
  width: 100%;
  height: auto;
  padding: 2.4rem;
  background-color: $dark;
  background-image: url("@Assets/images/footerMobile.png");
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  color: $white;

  @include small-desktop {
    bottom: 0;
    height: $footer-tablet-height;
    padding: 3.2rem;
    background-image: url("@Assets/images/footerDesktop.png");
  }

  @include large-desktop {
    height: $footer-height;
  }

  .footer-grid {
    gap: 5rem;
    grid-template-areas:
      "news news news news"
      "social social social social"
      "about about about about"
      "nav nav nav nav"
      "copy copy copy copy";
    grid-template-columns: repeat(4, 1fr);

    @include small-desktop {
      gap: 1rem 5rem;
      grid-template-areas:
        "news about nav social"
        "copy copy copy copy";
      grid-template-columns: 2.5fr 2.5fr 1fr 1fr;
    }

    > div {
      &.footer-newsletter {
        grid-area: news;
      }

      &.footer-about {
        grid-area: about;

        > p {
          line-height: 100%;

          @include small-desktop {
            line-height: 120%;
          }
        }
      }

      &.footer-navigate {
        grid-area: nav;

        ul li {
          line-height: 100%;

          @include small-desktop {
            line-height: 120%;
          }
        }
      }

      &.footer-social {
        min-width: 16rem;
        align-items: center;
        grid-area: social;

        @include small-desktop {
          align-items: flex-start;
          text-align: left;
        }
      }

      &.footer-copyright {
        flex-direction: column;
        align-items: baseline;
        padding-top: 2rem;
        padding-bottom: 2rem;
        grid-area: copy;
        justify-self: end;

        @include desktop {
          padding: 0;
        }

        @include large-desktop {
          padding: 2rem 0;
        }
      }

      .footer-social-icons {
        gap: 3.8rem;

        @include small-desktop {
          gap: .8rem;
        }

        img {
          height: 4.5rem;
          transition: transform .2s ease-in-out;

          @include small-desktop {
            height: 2.7rem;
          }

          &:hover {
            opacity: .9;
            transform: scale(.9) rotate(5deg);
          }
        }
      }

      h6,
      p {
        text-align: center;

        @include small-desktop {
          text-align: left;
        }
      }

      h6 {
        margin-bottom: 1.6rem;

        @include small-desktop {
          margin-bottom: .8rem;
        }
      }
    }
  }

  .footer-modal-text {
    color: $dark;
  }

  a {
    color: $white;
  }
}
