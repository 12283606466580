$tab-indicator-width: 6rem;

.tabs {
  position: relative;
  width: 100%;
  background-color: $white;

  &.side-fade {
    &::after {
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      width: 5rem;
      height: 5.5rem;
      background: rgb(255 255 255);
      background: linear-gradient(93deg, rgb(255 255 255 / 0) 0%, rgb(255 255 255 / 1) 100%);
      content: "";

      @include small-desktop {
        visibility: hidden;
      }
    }

    &::before {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 2rem;
      height: 5.5rem;
      background: rgb(255 255 255);
      background: linear-gradient(93deg, rgb(255 255 255 / 1) 0%, rgb(255 255 255 / 0) 100%);
      content: "";

      @include small-desktop {
        visibility: hidden;
      }
    }
  }

  ol.tab-list {
    position: relative;
    display: flex;
    padding: 1rem;
    overflow-y: auto;

    @include small-desktop {
      width: 100%;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .tab-list-item {
      position: relative;
      display: inline-block;
      flex-grow: 1;
      padding: .8rem 2rem;
      border: .1rem solid $white-600;
      margin-bottom: -.1rem;
      color: $gray-400;
      cursor: pointer;
      font-family: $avenir-font;
      font-size: $font-size-md;
      font-variant: all-small-caps;
      font-weight: 600;
      list-style: none;
      text-align: center;
      text-transform: uppercase;
      white-space: nowrap;

      &:first-child {
        border-right: 0;
        border-bottom-left-radius: $border-radius-5;
        border-top-left-radius: $border-radius-5;
      }

      &:last-child {
        border-left: 0;
        border-bottom-right-radius: $border-radius-5;
        border-top-right-radius: $border-radius-5;
      }

      &.tab-list-active {
        background-color: $white-600;
        color: $dark;

        &::before {
          left: calc(50% - ($tab-indicator-width / 2));
          width: $tab-indicator-width;
        }
      }

      &::before {
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0;
        height: .6rem;
        background-color: $primary-mint;
        border-top-left-radius: $border-radius-5;
        border-top-right-radius: $border-radius-5;
        content: "";
        transition: width .3s ease-in-out, left .3s ease-in-out;
      }

      &:hover {
        &::before {
          left: calc(50% - ($tab-indicator-width / 2));
          width: $tab-indicator-width;
        }
      }
    }
  }

  .tab-content {
    padding: .5rem 1rem;
  }
}
