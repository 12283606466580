$menu-transition: .35s;

.sidebar-nav {
  .user-id {
    margin-bottom: 0;

    @include small-desktop {
      margin-bottom: 6rem;
    }
  }

  .manager-id {
    padding: 1.25rem 1rem;
    background: $white-600;
    border-radius: $border-radius-5;

    @include small-desktop {
      margin: 1.5rem 1.5rem 3.3rem;
    }

    .icon-arrow {
      display: none;
      cursor: initial;
    }

    @include small-desktop {
      background: rgba($primary-blue, .1);

      .icon-arrow {
        display: block;
      }
    }
  }

  @include small-desktop {
    box-shadow: .5rem 0 1rem 0 rgb(174 174 174 / .25);
    overflow-y: auto;
  }

  h6 {
    overflow: hidden;
    max-width: 15rem;
    margin-right: 2rem;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .menu {
    &.mobile {
      position: fixed;
      z-index: 998;
      top: $navbar-height;
      left: 0;
      display: flex !important;
      width: 100vw;
      height: 100%;
      flex-direction: column;
      padding: 3.5rem 0 7rem;
      background: $white-600;
      overflow-y: auto;
      transform: translateX(-100%);
      transition: transform ($menu-transition * 2) ease-in-out;

      &.open {
        overflow-x: hidden;
        transform: translateX(0%);
      }
    }

    .close-icon {
      position: absolute;
      top: 2rem;
      right: 1rem;
    }

    .username {
      overflow: hidden;
      max-width: 15rem;
      font-variant: all-small-caps;
      text-overflow: ellipsis;
      text-transform: uppercase;
    }

    .options-wrapper {
      display: flex;
      width: 60%;
      flex-direction: column;
      margin-top: 7rem;
      margin-bottom: 10rem;
      gap: 11rem;

      @include small-desktop {
        width: 100%;
        margin-top: 0;
        margin-bottom: 0;
        gap: 13rem;
      }

      .options-section {
        width: 100%;

        h3 {
          margin-left: 1rem;
        }

        ul.section-list {
          display: flex;
          width: 100%;
          flex-direction: column;
          gap: .6rem;

          @include small-desktop {
            gap: .35rem;
          }

          li.menu-item {
            width: 100%;
            padding: 1.25rem 1rem;
            border-radius: $border-radius-5;
            cursor: pointer;

            @include small-desktop {
              margin-top: .5rem;
              margin-right: inherit;
              margin-left: inherit;
            }

            img {
              width: 2rem;
              height: 2rem;
              transition: filter .5s ease-in-out;
            }

            span {
              font-variant: all-small-caps;
              line-height: 0;
            }

            &:hover {
              background: rgba($primary-blue, .05);

              img {
                filter: invert(.5) sepia(.5) saturate(5) hue-rotate(191deg);
              }

              h2 {
                color: $primary-blue;
              }
            }

            &.menu-item-active {
              background: rgba($primary-blue, .1);

              img {
                filter: invert(.5) sepia(.5) saturate(5) hue-rotate(191deg);
              }

              span {
                color: $primary-blue;
                font-variant: all-small-caps;
                font-weight: 600;
                line-height: 0;
              }

              &:hover {
                background: rgba($primary-blue, .05);
              }
            }
          }
        }
      }
    }
  }

  .backdrop {
    position: fixed;
    z-index: 800;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: $dark;
    opacity: .95;
    pointer-events: all;
    transition: opacity ($menu-transition * 2) ease-in-out;

    &.hidden {
      opacity: 0;
      pointer-events: none;
    }
  }
}
