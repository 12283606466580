.button-default {
  display: flex;
  width: unset;
  height: 4rem;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border-radius: $border-radius-5;
  color: $white;
  cursor: pointer;
  font-family: $avenir-font;
  font-size: $font-size-md;
  font-weight: 600;

  .loader {
    width: 2rem;
    height: 2rem;
    border: solid .4rem $gray-300;
    border-top: solid .4rem $gray-400;
    animation: spin 1000ms linear infinite;
    border-radius: 50%;
    transform: translate(50%, -50%);
  }

  > span.icon,
  .loader {
    margin-right: 1rem;
    line-height: inherit;
  }

  > span.truncate {
    line-height: 1.6rem;
  }

  &:disabled {
    border: 0;
    background-color: $gray-500;
    color: $gray-300;
    cursor: not-allowed;

    &:hover,
    &:focus {
      background-color: $gray-500;
      box-shadow: unset;
    }
  }

  &.button-padding {
    padding: 1.2rem 3.8rem;
  }
}

/*
--------------------------------------------------------------------------------
Colors
--------------------------------------------------------------------------------
*/

.primary-style {
  background-color: $primary-mint;

  &:hover,
  &:focus {
    background-color: $active-primary-mint;
    box-shadow: 0 0 .4rem $primary-mint;
  }

  &.cancel-color {
    background-color: $secondary-red;
    color: $white;

    &:hover,
    &:focus,
    &:active {
      background-color: $active-secondary-red;
      box-shadow: 0 0 .4rem $secondary-red;
    }
  }

  &.outline {
    border: .1rem solid $primary-mint;
    background-color: transparent;
    color: $primary-mint;

    &:hover,
    &:focus {
      background-color: rgba($primary-mint, .1);
    }

    &:active {
      background-color: $primary-mint;
      color: $white;
    }

    &:disabled {
      border: .1rem solid $gray-500;
      color: $gray-300;
      cursor: not-allowed;
    }

    &.cancel-color {
      border: .1rem solid $secondary-red;
      background-color: transparent;
      color: $secondary-red;

      &:hover,
      &:focus {
        background-color: rgba($secondary-red, .1);
        box-shadow: unset;
        color: $secondary-red;
      }

      &:active {
        background-color: rgba($secondary-red, .2);
        color: $secondary-red;
      }
    }
  }
}

.secondary-style {
  background-color: $primary-blue;
  transition: background-color .3s ease-in-out;

  &:hover,
  &:focus {
    background-color: $active-primary-blue;
    box-shadow: 0 0 .4rem $primary-blue;
  }

  &.outline {
    border: .1rem solid $primary-blue;
    background-color: transparent;
    color: $primary-blue;

    &:hover,
    &:focus {
      background-color: rgba($primary-blue, .1);
    }

    &:active {
      background-color: $primary-blue;
      color: $white;
    }

    &:disabled {
      border: .1rem solid $gray-500;
      color: $gray-300;
      cursor: not-allowed;

      &:hover,
      &:focus {
        box-shadow: unset;
      }
    }
  }
}

.tertiary-style {
  height: auto;
  padding: 0;
  color: $gray-300;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;

  &:hover {
    filter: brightness(.85);
  }

  &:disabled {
    background-color: transparent;
    opacity: .7;

    &:hover {
      background-color: transparent;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
