@include fade-from-side(error-from-side, -2rem, 0);

$input-border: .1rem;

.input-group-persist-string {
  position: relative;
  display: flex;
  flex-direction: column;
  transition: height .2s ease-in-out;

  > .input-text {
    margin-bottom: .8rem;

    > label {
      margin-bottom: 1rem;
      color: $primary-blue;
      font-family: $avenir-font;
      font-size: $font-size-md;
      font-weight: 500;
    }

    > p {
      display: flex;
      overflow: hidden;
      margin-top: .8rem;
      -webkit-box-orient: vertical;
      color: $gray-300;
      font-size: $font-size-sm;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      text-overflow: ellipsis;
    }
  }

  .input {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: $white;
    border-radius: $border-radius-5;
    box-shadow: inset 0 0 0 $input-border $white-600;
    color: $gray-300;
    font-family: $avenir-font;
    font-size: $font-size-md;
    font-weight: 400;
    transition: box-shadow margin-bottom .2s ease-in-out;

    span {
      color: $dark;
    }

    &.error {
      margin-bottom: 1.5rem;
      box-shadow: inset 0 0 0 $input-border $secondary-red;

      &:hover {
        box-shadow: inset 0 0 0 $input-border $gray-400;
      }

      &:active {
        box-shadow: inset 0 0 0 $input-border $secondary-red;
      }

      &:focus {
        &::placeholder {
          color: transparent;
        }
      }
    }

    label.error {
      position: absolute;
      top: 4rem;
      left: 0;
      width: 100%;
      padding: 1rem 1rem 0;
      animation: error-from-side .5s ease-in-out forwards;
      color: $secondary-red;
      font-size: $font-size-sm;
    }
  }

  input {
    width: 100%;
    height: 4rem;
    padding: 0 .8rem;
    border-radius: $border-radius-5;
    color: $black;

    &::placeholder {
      color: $gray-300;
    }

    &:hover {
      box-shadow: inset 0 0 0 $input-border $gray-400;
      transition: box-shadow .2s ease-in-out;
    }

    &:active {
      box-shadow: inset 0 0 0 $input-border $primary-blue;
      transition: box-shadow .2s ease-in-out;
    }

    &:focus {
      &::placeholder {
        color: transparent;
      }
    }
  }

  .display {
    display: flex;
    width: 100%;
    height: 4rem;
    flex-direction: row;
    align-items: center;
    padding: 0 .8rem;
  }

  .input-icon {
    position: absolute;
    right: 0;
    bottom: .4rem;
    display: flex;
    width: 3rem;
    height: 3rem;
    box-sizing: border-box;
    align-items: center;

    &.error-icon {
      color: $secondary-red;
    }

    &.password-icon {
      color: $gray-400;
      cursor: pointer;
    }
  }
}
