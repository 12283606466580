$max-tier-visible: 5;
$tier-height-mobile: 12.1rem;
$tier-height-desktop: 9.7rem;

#panel-subscriptions {
  &.collapse-panel {
    max-height: calc($tier-height-mobile * $max-tier-visible);

    @include small-desktop {
      max-height: calc($tier-height-desktop * $max-tier-visible);
    }

    &.collapse-body {
      position: inherit;

      > ul {
        padding: 0 2rem;

        @include small-desktop {
          padding: 1rem 0;
        }
      }
    }

    &.collapsed {
      max-height: 0;
      padding: 0;
      opacity: 0;
    }
  }
}

.tier {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem 2rem;
  background-color: $white;
  border-radius: $border-radius-15;
  cursor: pointer;
  gap: 2.6rem;
  transition: background-color .5s ease-in-out;

  &:hover {
    background-color: rgba($gray-500, .15);
  }

  @include small-desktop {
    justify-content: flex-start;
    margin: .5rem 0;
  }

  .tier-image {
    width: 7rem;
    height: 7rem;
    flex-shrink: 0;

    @include small-desktop {
      width: 5.3rem;
      height: 5.3rem;
    }

    > img {
      width: 100%;
      object-fit: contain;
    }
  }

  .tier-info {
    width: 20rem;

    > h6 {
      overflow: hidden;
      max-width: 15rem;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    > h5 {
      overflow: hidden;
      max-width: 15rem;
      text-overflow: ellipsis;
    }

    @include small-desktop {
      max-width: 15rem;
    }
  }
}
