@include fade-from-above(faq-list-fade, 20, 0);

main.faqs {
  overflow: hidden;
  padding: 7rem 0;
  background-color: $white-600;

  img.hand-question {
    width: 17.3rem;
    height: 29rem;
    transform: rotate(20deg);

    @include desktop {
      margin-top: 30rem;
    }
  }

  img.decor-prop {
    position: absolute;

    &.prop-squares {
      top: -15rem;
      right: -15rem;
      transform: rotate(35deg);
    }

    &.prop-blob-left {
      top: 80rem;
      left: -30rem;

      @include small-desktop {
        top: 40rem;
        left: 0;
      }
    }

    &.prop-blob-right {
      top: 150rem;
      right: 0;
    }
  }

  section {
    &.intent-grid {
      display: grid;
      width: 100%;
      max-width: 120rem;
      min-height: 60vh;
      grid-template-columns: 1fr;

      a {
        color: $secondary-blue;
      }

      @include desktop {
        grid-template-columns: 44rem 1fr 30rem;

        div {
          &:nth-last-child(1) {
            grid-column-start: 3;
          }
        }
      }
    }

    &.faqs-list {
      max-width: 80rem;

      details {
        animation: faq-list-fade .5s ease-in-out;
        opacity: 0;

        @for $i from 1 through 30 {
          &:nth-child(#{$i}) {
            animation-delay: calc(.4s - .6s / (#{$i} * #{$i}) + #{$i} * .2s);
            animation-fill-mode: forwards;
          }
        }

        figure.attachment {
          margin-bottom: 2rem;
          text-align: center;

          > img {
            max-width: 100%;
            height: auto;
          }
        }

        /* stylelint-disable selector-class-pattern */
        figcaption.attachment__caption {
          display: none;
        }
        /* stylelint-enable selector-class-pattern */
      }
    }

    &.artists-grid {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      mask-image: linear-gradient(to bottom, rgb(0 0 0 / 1) 60%, rgb(0 0 0 / 0) 100%);
      place-items: center;

      @include small-desktop {
        grid-template-columns: repeat(6, 1fr);
        mask-image: radial-gradient(farthest-corner at top center, rgb(0 0 0 / 1) 30%, rgb(0 0 0 / 0) 100%);
      }

      @include desktop {
        grid-template-columns: repeat(8, 1fr);
      }

      @include large-desktop {
        grid-template-columns: repeat(12, 1fr);
      }

      .artist-card {
        width: min-content;
        max-width: 14rem;
        height: 18rem;
        border-radius: 2rem;
        color: black;
        cursor: pointer;
        opacity: 0;
        transform: translateY(0) scale(.6);

        &:nth-child(2n + 1) {
          margin-top: 6rem;
        }

        &:hover {
          transform: translateY(-2rem);
        }

        .avatar-border-grad {
          display: flex;
          width: 9.5rem;
          height: 9.5rem;
          align-items: center;
          border-radius: 50%;
        }
      }
    }
  }
}
