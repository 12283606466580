@include fade-from-side(error-from-side, -2rem, 0);

$input-border: .1rem;

.textarea-input-group {
  display: flex;
  flex-direction: column;

  > .input-text {
    margin-bottom: .8rem;

    > label {
      margin-bottom: 1rem;
      color: $primary-blue;
      font-family: $avenir-font;
      font-size: $font-size-md;
      font-weight: 500;
    }

    > p {
      display: flex;
      overflow: hidden;
      margin-top: .8rem;
      -webkit-box-orient: vertical;
      color: $gray-300;
      font-size: $font-size-sm;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      text-overflow: ellipsis;
    }
  }

  > .input {
    position: relative;
    height: auto;
    color: $dark;
    transition: box-shadow .2s ease-in-out;

    > textarea {
      width: 100%;
      min-height: 4rem;
      padding: .8rem 2.5rem .8rem .8rem;
      background-color: $white;
      border-radius: $border-radius-5;
      box-shadow: 0 0 0 .1rem $white-600;
      font-family: $avenir-font;
      font-size: $font-size-md;
      font-weight: 400;
      resize: vertical;

      &::placeholder {
        color: $gray-300;
      }

      &:hover {
        box-shadow: 0 0 0 $input-border $gray-400;
        transition: box-shadow .2s ease-in-out;
      }

      &:active {
        box-shadow: 0 0 0 $input-border $primary-blue;
        transition: box-shadow .2s ease-in-out;
      }

      &:focus {
        &::placeholder {
          color: transparent;
        }
      }
    }
  }

  &.disabled {
    > .input {
      > textarea {
        background-color: #ededed;
        color: #c2c2c2;
        cursor: not-allowed;
      }
    }
  }

  &.error {
    > .input {
      > textarea {
        box-shadow: 0 0 0 $input-border $secondary-red;

        &:hover {
          box-shadow: 0 0 0 $input-border $gray-400;
          transition: box-shadow .2s ease-in-out;
        }

        &:active {
          box-shadow: 0 0 0 $input-border $primary-blue;
          transition: box-shadow .2s ease-in-out;
        }

        &:focus {
          &::placeholder {
            color: transparent;
          }
        }
      }
    }

    > label.error {
      padding: 1rem;
      animation: error-from-side .5s ease-in-out forwards;
      color: $secondary-red;
      font-family: $avenir-font;
      font-size: $font-size-sm;
      font-weight: 400;
    }
  }

  .input-icon {
    position: absolute;
    top: .4rem;
    right: 0;
    display: flex;
    width: 3rem;
    height: 3rem;
    box-sizing: border-box;
    align-items: center;
    cursor: pointer;

    &.error-icon {
      color: $secondary-red;
    }

    &.password-icon {
      color: $gray-400;
    }
  }
}
