.collapse-panel-history {
  max-height: 40rem;
  transition: max-height 1s, opacity 1.25s ease-in-out;

  &.collapsed {
    overflow: hidden;
    max-height: 0;
    opacity: 0;
  }
}

.collapse-panel-header {
  padding: 1rem 1.5rem;
}

.table {
  .table-history {
    .amount-col,
    .buyer-col,
    .seller-col {
      overflow: hidden;
      min-width: 10rem;
      max-width: 10rem;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
