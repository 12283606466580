.token {
  position: relative;
  display: flex;
  width: 21.8rem;
  height: 26rem;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: .8rem 1.6rem;
  border-radius: $border-radius-15;
  box-shadow: 0 1rem 1.5rem 0 rgb(205 205 205 / .35);
  transition: transform .3s ease-in-out;

  h5,
  h6 {
    overflow: hidden;
    width: 70%;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .token-preview {
    width: 14.8rem;
    height: 14.8rem;
    object-fit: cover;

    @include small-desktop {
      width: 14.6rem;
      height: 14.6rem;
    }
  }

  &:hover {
    transform: rotate(3deg);
  }

  .token-price {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > img {
      padding: .25rem;
      border: .1rem solid $gray-500;
      border-radius: 50%;
    }
  }

  .favorite-icon {
    position: absolute;
    top: .5rem;
    right: 1rem;
    width: 4rem;
    height: 4rem;

    .favorite,
    .unfavorite {
      position: absolute;
      transition-duration: .25s;
      transition-property: opacity, transform;
      transition-timing-function: ease-in-out;
    }
  }

  .show-favorite {
    > .favorite {
      opacity: 1;
      transform: scale(1) rotate(0deg) translate(0, 0);
    }

    > .unfavorite {
      opacity: 0;
      transform: scale(.9);
    }
  }

  .hide-favorite {
    > .favorite {
      opacity: 0;
      transform: scale(.5) rotate(25deg) translate(1rem, -4rem);
    }

    > .unfavorite {
      opacity: 1;
      transform: scale(1);
    }
  }

  a {
    color: inherit;

    &:hover {
      text-decoration: none;
    }
  }
}

.token-anim-exit-active {
  animation: hide-token-from-grid .5s ease-in-out .5s forwards;
}

@keyframes hide-token-from-grid {
  0% {
    transform: scale(1);
  }

  30% {
    opacity: 1;
    transform: scale(1.1);
  }

  100% {
    opacity: 0;
    transform: scale(.2);
  }
}
