.tag {
  position: relative;
  display: flex;
  width: unset;
  height: 4rem;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  padding: 1.2rem 1.6rem;
  border: .1rem solid transparent;
  background-color: rgba($gray-400, .1);
  border-radius: 2rem;
  color: $dark;
  cursor: pointer;

  &:active {
    background-color: $primary-blue;
    color: $white;
  }

  &:hover,
  &:focus {
    border: .1rem solid $primary-blue;
  }

  span {
    &:first-child {
      font-family: $avenir-font;
      font-size: $font-size-md;
      font-weight: 500;
    }

    &.icon-close {
      margin-left: 1rem;
      color: $secondary-red;
    }
  }

  &:disabled {
    background-color: $white-600;
    color: $gray-400;
    cursor: not-allowed;

    &:hover,
    &:active,
    &:focus {
      border: .1rem solid transparent;
    }
  }
}
