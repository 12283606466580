.post {
  padding-bottom: 2rem;
  border-bottom: .1rem solid $gray-500;

  &:first-child {

    @include small-desktop {
      padding-top: 0;
    }
  }

  &:last-child {
    padding-bottom: 0;
    border-bottom: unset;
  }

  .post-header {

    @include small-desktop {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
    }

    a {
      font-size: $font-size-md;
    }

    h4 {
      font-size: $font-size-lg;
      font-variant: normal;
      text-transform: none;
    }
  }

  .post-time {
    font-size: $font-size-sm;

    @include small-desktop {
      flex-shrink: 0;
      order: 2;
    }
  }

  .post-blocked {
    position: relative;
    overflow: hidden;
    min-height: 45rem;

    .post-tier-blocked {
      position: absolute;
      background-color: rgba($gray-500, .8);

      h4 {
        font-size: $font-size-md;
        font-variant: all-small-caps;
        text-transform: uppercase;
      }

      p {
        font-size: $font-size-md;
      }

      .post-blocked-icon {
        width: 7rem;
        height: 6rem;

        > span {
          transform: scale(4);
        }
      }
    }
  }

  .post-image {
    width: auto;
    max-width: 100%;
    height: auto;
    object-fit: contain;

    @include desktop {
      min-height: 40rem;
    }
  }

  .post-tag {
    display: inline-block;
    overflow: hidden;
    border-radius: $border-radius-5;
    color: $gray-300;
    font-size: $font-size-sm;

    @include tablet {
      overflow: unset;
    }

    > a {
      display: inherit;
      overflow: inherit;
      color: inherit;
      font-size: $font-size-md;
      font-variant: all-small-caps;
      line-height: 110%;
      text-decoration: none;

      @include tablet {
        display: unset;
        overflow: unset;
      }
    }

    &:hover {
      filter: brightness(.95);
    }
  }

  .post-avatar {
    width: 5rem;
    height: 5rem;
    border-radius: 100%;
    cursor: pointer;

    &:hover {
      border: .3rem solid $white-600;
    }
  }

  .post-content {
    font-size: $font-size-md;
  }
}
