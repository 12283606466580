$tier-height: 8rem;
$max-tier-visible: 5;

.my-tokens-content > .my-tokens-section {
  position: unset;
  top: unset;

  @include large-desktop {
    position: sticky;
    top: 40rem;
  }

  &:nth-child(1) {

    @include small-desktop {
      order: 3;
    }
  }
}

.panel {
  &.panel-background {
    background-color: transparent;
    box-shadow: unset;

    @include small-desktop {
      background-color: $white;
      box-shadow: 0 1rem 1.5rem 0 rgb(205 205 205 / .35);
    }
  }

  &.panel-tokens {
    .grid {
      column-gap: 1rem;
      row-gap: 3rem;
    }

    @include small-desktop {
      background-color: transparent;
      box-shadow: unset;
    }
  }
}

main.my-tokens {
  display: grid;
  height: calc(100vh - $navbar-height);
  background-color: $white-600;
  grid-template-columns: 1fr;

  @include small-desktop {
    grid-template-columns: 28rem 1fr;
    overflow-y: hidden;
  }

  .side-container {
    overflow-y: unset;

    @include small-desktop {
      overflow-y: auto;
    }

    .side-container-wrapper {
      margin-bottom: 2rem;

      > p {
        font-size: 1.6rem;
      }
    }

    .mysubs-wrapper {
      position: unset;
      top: unset;

      @include small-desktop {
        position: sticky;
        top: 2rem;
      }

      .mysubs-expander {
        cursor: pointer;
      }
    }
  }

  .tokens-grid {
    position: relative;
    display: grid;
    align-self: center;
    gap: 3rem 1rem;
    grid-template-columns: repeat(1, 1fr);

    @include desktop {
      grid-template-columns: repeat(2, 1fr);
    }

    @include large-desktop {
      grid-template-columns: repeat(3, 1fr);
    }

    @include resolution(1600px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
