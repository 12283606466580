$radio-size: 2.1rem;
$inner-radio-size: calc($radio-size - .4rem);
$radio-border-radius: .3rem;

.checkbox {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  gap: 2rem;

  .checkbox-container {
    position: relative;
    width: $radio-size;
    height: $radio-size;
    cursor: inherit;

    input {
      position: absolute;
      top: 0;
      left: 0;
      width: $radio-size;
      height: $radio-size;
      align-self: center;
      border: .1rem solid $primary-blue;
      background-color: $white;
      border-radius: $radio-border-radius;
      cursor: inherit;

      &::before {
        position: absolute;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        background-color: $primary-blue;
        border-radius: $radio-size;
        color: $white;
        content: "";
        font-size: $font-size-sm;
        transform: scale(0);
        transition-duration: .3s;
        transition-property: transform, border-radius;
        transition-timing-function: ease-in-out;
      }

      &:checked {
        &::before {
          border-radius: 0;
          transform: scale(1);
        }
      }
    }

    > svg {
      position: absolute;
      z-index: 100;
      left: .2rem;
      width: $radio-size;
      height: $radio-size;
      pointer-events: none;
      transform: scale(.9);
    }

    &::selection {
      background-color: transparent;
    }
  }

  label {
    cursor: inherit;
    font-family: $avenir-font;
    font-size: 1.6rem;
    font-weight: 500;
  }

  &.disabled {
    cursor: not-allowed;

    label,
    span {
      color: $gray-400;
      pointer-events: none;
    }

    input {
      border: .1rem solid $gray-400;
      background-color: $white;
      pointer-events: none;

      &::before {
        border: .1rem solid $white;
        background-color: $gray-400;
      }
    }
  }
}

.check-path {
  animation: check 2s linear forwards;
  stroke-dasharray: 0;
  stroke-dashoffset: 0;
}

@keyframes check {
  from {
    stroke-dasharray: 0;
  }

  to {
    stroke-dasharray: 100;
  }
}
