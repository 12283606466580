main.token-page {
  height: 100%;
  background-color: $white-600;

  @include small-desktop {
    overflow-x: hidden;
  }

  .token-page-header {
    margin-top: 4rem;

    @include small-desktop {
      margin-top: 7.5rem;
    }
  }

  .list-token-page-header {
    margin-top: 3.5rem;

    @include small-desktop {
      margin-top: 11.2rem;
    }
  }

  a {
    color: $primary-blue;
  }

  .icon-scale {
    transform: scale(1.6);

    @include small-desktop {
      transform: scale(1);
    }
  }

  .token-page-content {
    position: unset;
    top: unset;
    display: flex;
    flex-direction: column;
    margin-bottom: 6.4rem;

    @include small-desktop {
      display: grid;
      margin-bottom: 7rem;
    }

    > div {
      &:nth-child(1) {

        @include small-desktop {
          width: 100%;
          order: 3;
        }
      }
    }

    .panel-tabs {
      width: 100%;
      height: 100%;

      > .tabs > .tab-content {
        display: flex;
        height: 100%;
        min-height: 20rem;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }

  .token-preview-grid {
    display: grid;
    width: 100%;
    gap: 5rem;
    grid-template-columns: 1fr;
    grid-template-rows: minmax(min-content, max-content) 1fr;
  }

  .panel {
    .panel-title {
      width: 100%;
      background-color: $white-600;
      border-radius: $border-radius-10;
    }

    &.panel-history {
      max-height: unset;
      padding: 1.5rem;
      transition: opacity .5s ease-in-out;

      &.fade-out {
        opacity: 0;
      }

      &.fade-in {
        opacity: 1;
      }
    }

    &.panel-token {
      height: max-content;
      padding: 1rem;

      @include small-desktop {
        min-height: 64.6rem;
        order: -1;
        padding: 3.2rem;
      }

      .panel-title {
        padding: 1rem 2rem;
      }

      .panel-info {
        width: 100%;
        padding: .5rem 2rem;
        background-color: $white-600;
        border-radius: $border-radius-20;
      }

      .panel-tabs-list-token {
        .tab-content {
          height: auto;
          max-height: unset;
          overflow-y: hidden;
        }

        .panel-tab-content {
          height: auto;
          max-height: unset;
          margin-bottom: 1.5rem;
          overflow-y: unset;

          .checkbox {
            label {
              font-family: $avenir-font;
              font-size: $font-size-md;
              font-weight: 500;
              line-height: 100%;
            }
          }
        }

        .date-info-box {
          background-color: rgb(84 79 246 / .1);
          color: $primary-blue;

          .icon-info > span {
            color: $primary-blue;
          }
        }
      }
    }

    ul.benefits-list {
      display: flex;
      flex-direction: column;
      gap: .8rem;

      li {
        display: list-item;
        list-style-position: inside;
        list-style-type: disc;

        span {
          margin-left: -1rem;
          font-size: 1.6rem;
        }
      }
    }
  }

  .panel-bids {
    padding: 1.5rem;
    transition: opacity .5s ease-in-out;

    .panel-title {
      padding: .97rem 1.8rem;
    }

    &.fade-out {
      opacity: 0;
    }

    &.fade-in {
      opacity: 1;
    }
  }

  .token-price {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: .5rem;
    gap: .5rem;

    > img {
      padding: .4rem;
      border: .1rem solid $gray-500;
      border-radius: 50%;
    }
  }

  .token-image {
    width: 100%;
    min-height: min-content;
    max-height: 55rem;
    background-color: rgba($gray-500, .4);
    object-fit: contain;

    @include small-desktop {
      transition-duration: 1s;
      transition-property: max-height;
      transition-timing-function: ease-in-out;
    }

    &.collapsed {

      @include small-desktop {
        max-height: 32rem;
      }
    }

    > .fallback {
      min-height: 50rem;
    }
  }

  .token-owner {
    overflow: hidden;
    max-width: 100vw;
    text-overflow: ellipsis;
  }

  .panel-tab-content {
    margin-bottom: 1.5rem;

    > .artist-name {
      color: $primary-blue;
    }

    .table {
      padding: 0;
    }
  }

  .button-back {
    margin-bottom: 2rem;
    margin-left: -1.5rem;

    > button > span {
      color: $primary-blue !important;
      transform: scale(3.5);

      @include small-desktop {
        transform: scale(1.6);
      }
    }

    .back-icon {
      > span {
        transform: scale(3);

        @include small-desktop {
          transform: scale(1.6);
        }
      }
    }

    a {
      padding-top: .3rem;
      color: $primary-blue;
      text-decoration: none;
    }
  }

  .input-eth-grid {
    gap: .5rem;
    grid-template-columns: 1fr 4rem;

    @include small-desktop {
      grid-template-columns: 15rem 4rem 1fr;
    }

    &.input-eth-grid-one-col {
      grid-template-columns: 1fr;

      @include small-desktop {
        grid-template-columns: 15rem 1fr;
      }
    }
  }

  .icon-eth-border {
    display: flex;
    width: 4rem;
    height: 4rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: .1rem solid $gray-500;
    background-color: rgba($gray-600, .5);
    border-radius: $border-radius-5;
    cursor: not-allowed;
  }

  .icon-eth {
    width: 2.2rem;
    height: 2.2rem;
  }

  .tab-content {
    height: auto;
    max-height: unset;
    overflow-y: hidden;

    @include small-desktop {
      max-height: 20rem;
    }
  }

  #listing-price-modal {
    height: 90vh;
    overflow-y: auto;

    @include small-desktop {
      width: 51.6rem;
      height: auto;
      padding: 3rem 5rem;

      .content {
        min-height: 60rem;
      }

      form {
        max-width: 28rem;
      }
    }
  }

  #checkout-modal {
    overflow-y: auto;

    .token-image {
      width: auto;
      max-width: 100%;
      height: 12.1rem;
      margin: 1rem;
      background-color: unset;
      object-fit: contain;
    }

    @include small-desktop {
      height: auto;
    }
  }

  #processing-modal {
    .operation-loader {
      position: relative;
      display: flex;
      width: 5rem;
      height: 5rem;
      align-items: center;
      justify-content: center;
      border-radius: 100%;

      .circle {
        width: 50%;
        height: 50%;
        background: $primary-mint;
        border-radius: 50%;
      }

      span {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        @for $i from 1 through 9 {
          &:nth-child(#{$i}) {
            transform: rotate(calc(45deg * #{$i}));

            &::before {
              position: absolute;
              top: 0;
              left: 0;
              width: 1.2rem;
              height: 1.2rem;
              animation: animate 2s linear infinite;
              animation-delay: calc(.25s * #{$i});
              background: $dark;
              border-radius: 50%;
              content: "";
            }
          }
        }
      }
    }
  }

  #initialize-account-modal {
    .text-content {
      max-width: 35.7rem;
    }
  }

  #offer-modal {
    min-width: unset;

    @include small-desktop {
      min-width: 76.8rem;
    }

    img.offer-img {
      width: 8rem;
      height: 8rem;
    }

    .token-price-image {
      padding: .25rem;
      border: .1rem solid $gray-500;
      border-radius: 50%;
    }

    .table {
      width: 100%;

      @include small-desktop {
        width: 39rem;
      }
    }
  }

  #share-token-modal {
    ul.social-share-links {
      li {
        &:hover {
          filter: brightness(.95);
        }
      }
    }

    @include small-desktop {
      width: 35.6rem;
    }
  }

  @keyframes animate {
    0% {
      transform: scale(1);
    }

    100% {
      transform: scale(0);
    }
  }
}

.date-info-update {
  background-color: rgb(84 79 246 / .1);
  color: $primary-blue;

  .icon-info > span {
    color: $primary-blue;
  }
}

.table {
  .table-list-bids {
    margin-top: 2rem;

    .bid-col {
      min-width: 10rem;
      max-width: 10rem;
    }

    .buyer-col,
    .expires-col {
      overflow: hidden;
      min-width: 15rem;
      max-width: 15rem;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.table-list-bids-empty {
  height: 8rem;
}
